import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import NotFoundImage from '../../styles/img/moonMan-asking.png'; // Replace with the path to your image

const NotFound = () => {
    return (
        <Container
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundColor: 'background.default',
            } }
        >
            <Box
                component="img"
                src={ NotFoundImage }
                alt="404 Not Found"
                sx={ {
                    width: '100%',
                    maxWidth: '400px',
                    marginBottom: 4,
                } }
            />
            <Typography
                variant="h3"
                sx={ { marginBottom: 2 } }
            >
                404 Not Found
            </Typography>
            <Typography
                variant="h6"
                sx={ { color: 'text.secondary' } }
            >
                Oops! The page you are looking for does not exist.
            </Typography>
        </Container>
    );
};

export default NotFound;
