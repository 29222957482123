import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import { getPrayerTime } from '../../../../services/selectors/prayersSelectors';
import { useSelector } from 'react-redux';

const GlassedBox = React.lazy( () => import( '../../../../layouts/glassmorphism/boxGlassed' ) );
const PrayerWidget = React.lazy( () => import( '../../../../views/app/widget/mobile' ) );
const Marquee = React.lazy( () => import( '../../../../layouts/marquee' ) );

const PrayerTimePortrait = () => {
    const prayers = useSelector( getPrayerTime );
    return (
        <Suspense fallback={ <></> }>
            { prayers && <Box sx={ { height: '100%', marginLeft: '-1vh' } }>
                <Box sx={ { height: '85%' } }>
                    <PrayerWidget widget={ true } />
                </Box>
                <GlassedBox gmode='gm-dmode' styles={ { marginTop: '1.5vh', marginBottom: '.5vh', marginLeft: '.5vh' } }>
                    <Marquee marginTop='1vh' />
                </GlassedBox>
            </Box> }
        </Suspense>
    );
};

export default React.memo( PrayerTimePortrait );



