import axios from "axios";
import { localPrayerTime } from "../Utils/fetchLocalStorage";
import { fetchDataFromServer, fetchDataFromLocalStorage, handleVersionChange, isOnline, managePhoneNumStorage, isLocalStorageSupported, isOverseasCountry } from '../Utils/apiService';

import {
    GET_ADMIN_PRAYER_TV,
    SET_SOME_POSTS,
    GET_TV_CODE
} from "./types";



export const getPrayerEntityTv = ( phoneNum, online = true ) => async dispatch => {
    let payload;

    try {
        if ( online ) {
            managePhoneNumStorage( phoneNum );
            const data = await fetchDataFromServer( phoneNum );
            if ( isLocalStorageSupported() ) {
                const currentVersion = localStorage.getItem( 'version' );
                handleVersionChange( currentVersion, data.data.version, phoneNum, data );
            }
            if ( isOverseasCountry( data.data.entity.country ) ) {
                payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
            } else {
                payload = data.data;
            }

        } else {
            if ( isLocalStorageSupported() ) {
                payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
            }
        }
    } catch ( error ) {
        try {
            // console.log( error );
            payload = await fetchDataFromLocalStorage( phoneNum, localPrayerTime );
        } catch ( e ) {
            // console.log( e ); // Log error when no data received or other issues
        }
    }

    if ( payload ) {
        dispatch( {
            type: GET_ADMIN_PRAYER_TV,
            payload: payload
        } );
    }
};


/**
 * Post question
 * @param {*} user
 */
export const somePostsManagments = ( somePostsLength, tvPosts, originalPosts ) => async dispatch => {

    try {

        if ( tvPosts <= 1 ) {

            dispatch( {
                type: SET_SOME_POSTS,
                payload: originalPosts.length
            } );
        } else {
            dispatch( {
                type: SET_SOME_POSTS,
                payload: tvPosts
            } );

        }

    } catch ( error ) {
        // console.log( error );
    }
};


export const getDigits = ( code ) => async ( dispatch ) => {
    try {
        const res = await axios.get( `/tapy/masjid/tv/new/digits/${ code }` );

        // Check if the code exists and is a valid string of the expected length
        const tvCode = res?.data?.tvCode;
        if ( tvCode && typeof tvCode.code === 'string' && tvCode.code.length === 6 ) {
            localStorage.setItem( "code", tvCode.code );
            dispatch( {
                type: GET_TV_CODE,
                payload: tvCode
            } );

            return tvCode.code;
        } else {
            return null; // Or throw an error
        }
    } catch ( error ) {
        console.error( 'Error fetching TV code:', error );
    }
};


export const setDigits = ( code ) => async dispatch => {

    try {
        const res = await axios.get( `/tapy/masjid/tv/get/digits/${ code }` );
        return res.data;

    } catch ( error ) {
        // console.log( error );
        // localStorage.removeItem( "code" );
        console.log( error );
        return false;
    }
};

