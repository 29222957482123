import React, { useState, useMemo } from 'react'; // Correct way to import React and its hooks
import { getIsIqama } from '../../../services/selectors/prayersSelectors';
import GlassedBox from '../../../layouts/glassmorphism/boxGlassed';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';

const LazyAdminSalat = React.lazy( () => import( '../../wrappers/admin' ) );

const Customizer = () => {
    const isIqama = useSelector( getIsIqama );
    const [ open, setOpen ] = useState( false );
    const toggleDrawer = ( newOpen ) => ( event ) => {
        if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {
            return;
        }
        setOpen( newOpen );
    };

    const drawerContent = useMemo( () => (
        open &&
        <LazyAdminSalat />
    ), [ open ] );

    return (
        isIqama ? <div onClick={ ( event ) => event.stopPropagation() }>
            <GlassedBox
                extraClass={ 'customizer-toggler' }
                styles={ {
                    right: 0,
                    top: '41.5%',
                    display: 'flex',
                    cursor: 'pointer',
                    position: 'fixed',
                    padding: '0.7vw',
                    zIndex: 1,
                    borderTopLeftRadius: '1.5vh',
                    borderBottomLeftRadius: '1.5vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1vw',
                    height: '1vw',
                    width: '1vw',

                } }
            >
                <IconButton onClick={ toggleDrawer( true ) }>
                    <i className="material-icons" style={ { fontSize: '2vh', lineHeight: '1', color: "#fff" } }>menu</i>
                </IconButton>
            </GlassedBox>
            <Drawer
                anchor='right'
                aria-hidden={ open ? 'false' : 'true' }
                open={ open }
                onClose={ toggleDrawer( false ) }
                ModalProps={ {
                    sx: {
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                } }
                PaperProps={ {
                    sx: {
                        position: 'relative',
                        width: '100%',
                        maxWidth: '100vw',
                        m: 0,
                        top: '20%',
                        backgroundColor: 'transparent',
                        backdropFilter: 'blur(10px)',
                        overflow: 'hidden',
                    },
                } }
            >
                <IconButton
                    onClick={ toggleDrawer( false ) }
                    sx={ {
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: '#fff',
                        marginRight: '1vw',
                    } }
                >
                    <i className="material-icons">close</i>
                </IconButton>
                <br />
                <br />
                { drawerContent }
            </Drawer>
        </div> : null
    );
};

export default React.memo( Customizer );
