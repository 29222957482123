import React from 'react';
import { useSelector } from 'react-redux';
import GlassedBox from '../../glassmorphism/boxGlassed';
import GlassMorphismDate from '../../glassmorphism/date';
import GlassMorphismClock from '../../glassmorphism/clock';
import { getIsIqama } from '../../../services/selectors/prayersSelectors';

const ModernClock = () => {
    const isIqama = useSelector( getIsIqama );

    const glassedBoxStyles = {
        margin: '0.5vh',
        width: !isIqama ? '50%' : '45%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '2vh',
        overflow: 'hidden',
        gap: '0.5vh',
        marginRight: '0.5vh',
    };

    return (
        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles }>
            <GlassMorphismClock height="90%" />
            <GlassMorphismDate />
        </GlassedBox>
    );
};

export default React.memo( ModernClock );
