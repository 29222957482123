import {
    SET_SHURUQ,
    SET_IQAMA_APP,
    SET_ADHAN_APP,
    SET_THIRD_NIGHT,
    SET_MIDDLE_NIGHT,
    SET_DARK_MODE_APP,
    SET_FOCUS_MODE_APP,
} from "./types";

export const setAdhan = ( payload ) => ( {
    type: SET_ADHAN_APP,
    payload
} );

export const setIqama = ( payload ) => ( {
    type: SET_IQAMA_APP,
    payload
} );

export const setShuruq = ( payload ) => ( {
    type: SET_SHURUQ,
    payload
} );

export const setThirdNight = ( payload ) => ( {
    type: SET_THIRD_NIGHT,
    payload
} );

export const setMiddleNight = ( payload ) => ( {
    type: SET_MIDDLE_NIGHT,
    payload
} );

export const setDarkMode = ( payload ) => ( {
    type: SET_DARK_MODE_APP,
    payload
} );


export const setFocusMode = ( payload ) => ( {
    type: SET_FOCUS_MODE_APP,
    payload
} );
