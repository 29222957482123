// utils.js
import axios from 'axios';
import UAParser from 'ua-parser-js';

async function getClientInfo () {
    // Try to get cached data from localStorage
    const cachedData = localStorage.getItem( '945durqxu' );
    if ( cachedData ) {
        return JSON.parse( cachedData );
    }

    // Initialize UAParser
    const parser = new UAParser();
    const result = parser.getResult();

    // Get client IP using an external service
    const ipResponse = await fetch( 'https://api.ipify.org?format=json' );
    const ipResult = await ipResponse.json();

    // Prepare the client information to be cached
    const clientInfo = {
        userAgent: result,
        ip: ipResult.ip,
    };

    // Cache the client information in localStorage
    localStorage.setItem( '945durqxu', JSON.stringify( clientInfo ) );

    return clientInfo;
}

export const getFlyerData = async ( digits ) => {
    try {

        const clientInfo = await getClientInfo();
        const res = await axios.post( `/tapy/screens/post/flyerscreen/${ digits }`, {
            clientDetails: clientInfo,
        } );
        if ( !res.data.screen ) {
            throw new Error( 'No data received from server' );
        }
        return res.data;
    } catch ( error ) {
        return false;
    }

};



