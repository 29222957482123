import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { calculeNextIqama } from '../Utils/formatDate';
import { useSelector } from 'react-redux';
import {
    getIsIqama,
} from '../services/selectors/appStatusSelectors';

const usePrayerTimeState = ( iqamaTime, tomorrowIqamaTime ) => {

    const isIqamaApp = useSelector( getIsIqama );

    const [ state, setState ] = useState( {
        Isha: false,
        Maghrib: false,
        Asr: false,
        Dhuhr: false,
        Fajr: false,
        iDiff: false,
        mDiff: false,
        aDiff: false,
        dDiff: false,
        fDiff: false,
    } );

    useEffect( () => {
        let now = dayjs().format( "HH:mm" );
        if ( calculeNextIqama( iqamaTime, "Isha" ) ) {
            setState( { Isha: true, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Maghrib" ) ) {
            setState( { Maghrib: true, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Asr" ) ) {
            setState( { Asr: true, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Dhuhr" ) ) {
            setState( { Dhuhr: true, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Fajr" ) && now > iqamaTime.Fajr ) {
            setState( { Fajr: true, iDiff: tomorrowIqamaTime.iIsDiff, mDiff: tomorrowIqamaTime.mIsDiff, aDiff: tomorrowIqamaTime.aIsDiff, dDiff: tomorrowIqamaTime.dIsDiff, fDiff: tomorrowIqamaTime.fIsDiff } );
        } else if ( calculeNextIqama( iqamaTime, "Fajr" ) ) {
            setState( { Fajr: true, iDiff: false, mDiff: false, aDiff: false, dDiff: false, fDiff: false } );
        }
    }, [ isIqamaApp, iqamaTime ] );



    return state;
};


export default usePrayerTimeState;