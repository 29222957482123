import React, { createContext, useContext, useState, useEffect } from 'react';

const TokenContext = createContext();

export const TokenProvider = ( { children, token, refetchData, dinScreenId } ) => {
    const [ contextToken, setContextToken ] = useState( token );

    useEffect( () => {
        setContextToken( token );
    }, [ token ] );

    return (
        <TokenContext.Provider value={ { token: contextToken, refetchData, dinScreenId } }>
            { children }
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    return useContext( TokenContext );
};
