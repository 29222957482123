import { format, differenceInSeconds, subMinutes, addHours, addDays, differenceInMilliseconds, differenceInMinutes, add, sub, addMilliseconds, subMilliseconds, isAfter, isBefore, parse, parseISO, addMinutes } from 'date-fns'
let backUp = {
    hijri: [
        { date: 'Csv date Is wrong ', day: ' ' },
        { date: 'Csv date Is wrong ', day: ' ' },
        { date: 'Csv date Is wrong ', day: ' ' },
        { date: 'Csv date Is wrong ', day: ' ' },
        { date: 'Csv date Is wrong ', day: ' ' }
    ],
    hijriEn: [
        { date: 'Csv Date is Wrong ', day: '' },
        { date: 'Csv Date is Wrong ', day: '' },
        { date: 'Csv Date is Wrong ', day: "" },
        { date: 'Csv Date is Wrong ', day: '' },
        { date: 'Csv Date is Wrong ', day: "" }
    ]
}
export const localPrayerTime = async function ( phone ) {
    let returnedData = {};
    let prayerTime = localStorage.getItem( phone );
    if ( prayerTime ) {
        let { data } = JSON.parse( prayerTime );
        returnedData = await getPrayerTimeWithPrayerTimeYear( data );
        return returnedData;
    } else {
        return false;
    }

}

const getPrayerTimeWithPrayerTimeYear = async ( timing ) => {
    try {
        let processedData;
        let localInfo;
        //> 12 csv
        if ( timing.prayerTimeYear.length > 12 ) {
            localInfo = await getPrayerTimeLocalCSV( timing );
            if ( localInfo ) {
                processedData = await getOtherInfo( timing );
            }
            return timing;
        } else {
            localInfo = await getPrayerTimeLocal( timing );
            if ( localInfo ) {
                processedData = await getOtherInfo( timing );
            }
            return timing;
        }
    } catch ( error ) {
        console.log( "error", error );
    }
}

async function getOtherInfo ( timing ) {

    try {

        timing.somePosts = []
        let tomorrowIqamaTime = timing.tomorrowIqamaTime
        timing.isOnline = false;
        tomorrowIqamaTime.fIsDiff = false;
        tomorrowIqamaTime.dIsDiff = false;
        tomorrowIqamaTime.aIsDiff = false;
        tomorrowIqamaTime.mIsDiff = false;
        tomorrowIqamaTime.iIsDiff = false;
        timing.tvSettings.activated = false;
        timing.tomorrowIqamaTime = tomorrowIqamaTime;
        return true;
    } catch ( error ) {
        console.log( error );
        return false;
    }
}

async function getPrayerTimeLocalCSV ( timing ) {

    try {
        let myFile = timing.prayerTimeYear;
        let theEntitiesProfiles = {};
        let apiPrayerTime = timing.apiPrayerTime
        let prayerTime = timing.prayerTime
        let iqamaTime = timing.iqamaTime

        //all the file
        for ( let index = 0; index < myFile.length; index++ ) {

            let myDate = new Date( myFile[ index ].date );
            let formattedDate = format( myDate, "MM-dd" );
            let currentDate = format( new Date(), "MM-dd" );
            if ( formattedDate === currentDate ) {
                let masjidDay = myFile[ index ];
                apiPrayerTime.Fajr = format( parse( await formatTimeWithAmPm( masjidDay.adhanFajr ), "HH:mm", new Date() ), "HH:mm" );
                apiPrayerTime.Dhuhr = format( parse( await formatTimeWithAmPm( masjidDay.adhanDhuhr ), "HH:mm", new Date() ), "HH:mm" );
                apiPrayerTime.Asr = format( parse( await formatTimeWithAmPm( masjidDay.adhanAsr ), "HH:mm", new Date(), ), "HH:mm" );
                apiPrayerTime.Maghrib = format( parse( await formatTimeWithAmPm( masjidDay.adhanMaghrib ), "HH:mm", new Date(), ), "HH:mm" );
                apiPrayerTime.Isha = format( parse( await formatTimeWithAmPm( masjidDay.adhanIsha ), "HH:mm", new Date(), ), "HH:mm" );


                if ( !masjidDay.hijriDates ) {
                    apiPrayerTime.HijriDates = backUp
                } else {
                    apiPrayerTime.HijriDates = masjidDay.hijriDates
                }
                apiPrayerTime.Midnight = await midnight( format( parse( await formatTimeWithAmPm( apiPrayerTime.Fajr ), "HH:mm", new Date() ), "HH:mm" ),
                    format( parse( await formatTimeWithAmPm( apiPrayerTime.Maghrib ), "HH:mm", new Date() ), "HH:mm" ) );
                apiPrayerTime.Imsak = await lastThirdNight( format( parse( await formatTimeWithAmPm( apiPrayerTime.Maghrib ), "HH:mm", new Date() ), "HH:mm" ),
                    format( parse( await formatTimeWithAmPm( apiPrayerTime.Fajr ), "HH:mm", new Date() ), "HH:mm" ) );

                prayerTime.Fajr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaFajr ), "HH:mm", new Date() ), "HH:mm" );
                prayerTime.Dhuhr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaDhuhr ), "HH:mm", new Date() ), "HH:mm" );
                prayerTime.Asr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaAsr ), "HH:mm", new Date() ), "HH:mm" );
                prayerTime.Maghrib = format( parse( await formatTimeWithAmPm( masjidDay.iqamaMaghrib ), "HH:mm", new Date() ), "HH:mm" );
                prayerTime.Isha = format( parse( await formatTimeWithAmPm( masjidDay.iqamaIsha ), "HH:mm", new Date() ), "HH:mm" );
                prayerTime.Jumua = format( parse( await formatTimeWithAmPm( masjidDay.Jumua1 ), "HH:mm", new Date() ), "HH:mm" );

                iqamaTime.Fajr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaFajr ), "HH:mm", new Date() ), "HH:mm" );
                iqamaTime.Dhuhr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaDhuhr ), "HH:mm", new Date() ), "HH:mm" );
                iqamaTime.Asr = format( parse( await formatTimeWithAmPm( masjidDay.iqamaAsr ), "HH:mm", new Date() ), "HH:mm" );
                iqamaTime.Maghrib = format( parse( await formatTimeWithAmPm( masjidDay.iqamaMaghrib ), "HH:mm", new Date() ), "HH:mm" );
                iqamaTime.Isha = format( parse( await formatTimeWithAmPm( masjidDay.iqamaIsha ), "HH:mm", new Date() ), "HH:mm" );
                if ( masjidDay.Jumua2 && masjidDay.Jumua2 !== '' ) {
                    prayerTime.toggleJumua = true;
                    prayerTime.Jumua2 = format( parse( await formatTimeWithAmPm( masjidDay.Jumua1 ), "HH:mm", new Date() ), "HH:mm" );
                }

                timing.apiPrayerTime = apiPrayerTime;
                timing.prayerTime = prayerTime;
                timing.iqamaTime = iqamaTime;
                index = myFile.length;
            }
        }
        return true;

    } catch ( error ) {
        console.log( error );
        return false;
    }
}
async function getPrayerTimeLocal ( timing ) {

    try {
        const currentDate = new Date();
        let d = format( currentDate, "dd" );
        let m = currentDate.getMonth()
        let day = ( Number( d ) - 1 )
        let month = ( Number( m ) )
        let myFile = timing.prayerTimeYear;
        let apiPrayerTime = timing.apiPrayerTime
        let iqamaTime = timing.iqamaTime

        try {
            if ( myFile.length <= 12 ) {
                // console.log( "day", day )
                // console.log( "monht", month )
                let masjidDay = myFile[ month ][ day ];
                // console.log( format( parse( masjidDay.adhanFajr, "HH:mm", new Date() ), "HH:mm" ) );
                apiPrayerTime.Fajr = await adhanCalcul( format( parse( masjidDay.adhanFajr, "HH:mm", new Date() ), "HH:mm" ), timing.prayerTime.FautoA )
                apiPrayerTime.Sunrise = format( parse( masjidDay.shourouk, "HH:mm", new Date() ), "HH:mm" );
                apiPrayerTime.Dhuhr = await adhanCalcul( format( parse( masjidDay.adhanDhuhr, "HH:mm", new Date(), ), "HH:mm" ), timing.prayerTime.DautoA )
                apiPrayerTime.Asr = await adhanCalcul( format( parse( masjidDay.adhanAsr, "HH:mm", new Date(), ), "HH:mm" ), timing.prayerTime.AautoA )
                apiPrayerTime.Maghrib = await adhanCalcul( format( parse( masjidDay.adhanMaghrib, "HH:mm", new Date(), ), "HH:mm" ), timing.prayerTime.MautoA )
                apiPrayerTime.Isha = await adhanCalcul( format( parse( masjidDay.adhanIsha, "HH:mm", new Date(), ), "HH:mm" ), timing.prayerTime.IautoA )
                //options
                apiPrayerTime.Midnight = await midnight( format( parse( timing.apiPrayerTime.Fajr, "HH:mm", new Date() ), "HH:mm" ), format( parse( timing.apiPrayerTime.Maghrib, "HH:mm", new Date() ), "HH:mm" ) );
                apiPrayerTime.Imsak = await lastThirdNight( format( parse( timing.apiPrayerTime.Maghrib, "HH:mm", new Date() ), "HH:mm" ), format( parse( timing.apiPrayerTime.Fajr, "HH:mm", new Date() ), "HH:mm" ) );

                if ( !masjidDay.hijriDates ) {
                    apiPrayerTime.HijriDates = backUp
                } else {
                    apiPrayerTime.HijriDates = masjidDay.hijriDates
                }
                apiPrayerTime.HijriDate = timing.apiPrayerTime.HijriDate

                iqamaTime.Fajr = await iqamaCalcul( timing.prayerTime.Fajr, timing.prayerTime.Fauto, apiPrayerTime.Fajr );
                iqamaTime.Dhuhr = await iqamaCalcul( timing.prayerTime.Dhuhr, timing.prayerTime.Dauto, apiPrayerTime.Dhuhr );
                iqamaTime.Asr = await iqamaCalcul( timing.prayerTime.Asr, timing.prayerTime.Aauto, apiPrayerTime.Asr );
                iqamaTime.Maghrib = await iqamaCalcul( timing.prayerTime.Maghrib, timing.prayerTime.Mauto, apiPrayerTime.Maghrib );
                iqamaTime.Isha = await iqamaCalcul( timing.prayerTime.Isha, timing.prayerTime.Iauto, apiPrayerTime.Isha );

                timing.iqamaTime = iqamaTime
                timing.apiPrayerTime = apiPrayerTime

                return true;
            }

        } catch ( e ) {
            console.log( e );
        }
    } catch ( error ) {
        console.log( error );
        return false;
    }
}

async function adhanCalcul ( adhan, addedTime ) {
    try {
        if ( Number( addedTime ) !== 0 ) {

            let theAdhan = addMinutes( parse( adhan, "HH:mm", new Date() ), Number( addedTime ) );
            return format( theAdhan, "HH:mm" );

        } else {
            return adhan;
        }
    } catch ( error ) {
        console.log( "ICICICICICICIC" );
        return adhan
    }


};

async function iqamaCalcul ( iqama, addedTime, adhan ) {
    try {
        if ( Number( addedTime ) > 0 ) {
            const theIqama = addMinutes( parse( adhan, "HH:mm", new Date() ), Number( addedTime ) );
            return format( theIqama, "HH:mm" );

        } else {
            return ( !iqama || iqama === "N/A" || iqama === '' ) ? adhan : iqama;
        }
    } catch ( error ) {
        return adhan
    }


};


async function midnight ( fajr, maghrib ) {
    try {
        let begin = parse( maghrib, "HH:mm", new Date() );
        let end = parse( fajr, "HH:mm", new Date() );
        // If the end time is smaller than the begin time, it means it crosses midnight
        if ( isBefore( end, begin ) ) {
            end = addDays( end, 1 );
        }
        var duration = differenceInMilliseconds( end, begin );
        var hours = duration / 1000 / 60 / 60;
        return format( addMilliseconds( begin, duration / 2 ), "HH:mm" );
    } catch ( error ) {
        return "01:38";
    }
}

async function lastThirdNight ( maghrib, fajr ) {
    try {

        let begin = parse( maghrib, "HH:mm", new Date() );
        let end = parse( fajr, "HH:mm", new Date() );
        // If the end time is smaller than the begin time, it means it crosses midnight
        if ( isBefore( end, begin ) ) {
            end = addHours( end, 24 );
        }
        var duration = differenceInMilliseconds( end, begin );
        var hours = duration / 1000 / 60 / 60;
        return format( subMilliseconds( end, duration / 3 ), "HH:mm" );


    } catch ( error ) {
        return "01:38";
    }
}

async function formatTimeWithAmPm ( time ) {
    let returnedTime = time;
    let hour;
    if ( time.indexOf( "AM" ) > 1 || time.indexOf( "PM" ) > 1 ) {
        const myTime = time;
        const [ hours, minutes ] = myTime.split( ":" );
        const [ , period ] = minutes.split( " " );
        const hour24 = ( period === "PM" ? +hours + 12 : + hours ) % 24;
        if ( hours === "12" && period === "PM" ) {
            hour = 12;
        } else {
            hour = hour24;
        }
        const formattedTime = `${ hour }:${ minutes.slice( 0, 2 ) }`;
        const myDate = parse( `1970-01-01T${ formattedTime }`, "yyyy-MM-dd'T'HH:mm", new Date() );
        returnedTime = format( myDate, "HH:mm" );
    }

    return returnedTime;





}

