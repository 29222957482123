import React, { Suspense } from 'react';
import { getPlaying, getTypeAudio, getActualPost } from '../../../services/selectors/playerSelectors';
import { getIsIqama, getPrayerTime } from '../../../services/selectors/prayersSelectors';
import ModernClock from '../../../layouts/clocks/modern';
import TiersNight from '../modern/events/tiersnight';
import Midnight from '../modern/events/midnight';
import Shouruq from '../modern/events/shouruq';
import JumuaModern from '../modern/jumuas';
import { useSelector } from 'react-redux';
import LogoModern from '../modern/logo';
import Eid from '../../../layouts/eid';
import Salat from '../modern/salat';
import { Box } from '@mui/material';

const LazyDualView = React.lazy( () => import( '../../space/dual' ) );
const LazyAlternatingComponents = React.lazy( () => import( '../../wrappers/hoc/withMarqueeAndNext' ) );

const FullscreenDividedComponent = () => {
    const { actualPost } = useSelector( getActualPost );
    const prayers = useSelector( getPrayerTime );
    const isAudio = useSelector( getTypeAudio );
    const isIqama = useSelector( getIsIqama );
    const playing = useSelector( getPlaying );

    const headerBoxStyles = {
        height: '5%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: isIqama ? '1vw' : '2.5vw',
        marginTop: isIqama ? '0.5vw' : '1.5vh',
    };

    const middleBoxStyles = {
        height: '35%',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '1vh',
        marginLeft: '1vh',
        marginTop: '-1vh',
    };

    return (
        <>
            { ( isAudio && actualPost?.text ) || ( !isAudio && playing ) ? (
                <Suspense fallback={ <></> }>
                    <LazyDualView />
                </Suspense>
            ) : (
                <>
                    <Box sx={ headerBoxStyles }>
                        <Shouruq />
                        <Midnight />
                        <TiersNight />
                    </Box>
                    <Box sx={ middleBoxStyles }>
                        <ModernClock />
                        { isIqama && <JumuaModern /> }
                        { prayers?.toggleEid ? <Eid prayers={ prayers } /> : <LogoModern /> }
                    </Box>
                    <Salat />
                </>
            ) }

            <Suspense fallback={ <></> }>
                <LazyAlternatingComponents />
            </Suspense>
        </>
    );
};

export default React.memo( FullscreenDividedComponent );
