import {
    SET_LANGUAGE,
    CHANGE_LANGUAGE
} from './types';

// Action to set the language immediately
export const setLanguage = ( language ) => {
    return {
        type: SET_LANGUAGE,
        payload: language
    };
};

// Action to smoothly change the language with animations
export const changeLanguage = ( newLanguage ) => {
    return {
        type: CHANGE_LANGUAGE,
        payload: { newLanguage }
    };
};
