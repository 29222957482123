import React from 'react';

const CustomSkeletonLoader = ( { width = '20vh', height = '4vh', style = {} } ) => {
    const loaderStyle = {
        width: width,
        height: height,
        ...style
    };

    return (
        <div className="custom-skeleton-loader" style={ loaderStyle }>
            <span className="loader"></span>
        </div>
    );
};

export default CustomSkeletonLoader;
