
import {
    TOGGLE_PLAY,
    RESET_MEDIA_INDEX,
    TOGGLE_LOOPING

} from "./types";

export const togglePlay = ( isPlaying ) => {
    return {
        type: TOGGLE_PLAY,
        payload: isPlaying
    };
};

// Action to reset the media index to the beginning of the playlist
export const resetMediaIndex = () => {
    return {
        type: RESET_MEDIA_INDEX
    };
};
export const toggleLooping = () => {
    return {
        type: TOGGLE_LOOPING,
    };
};