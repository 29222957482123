


import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

import { somePostsManagments } from "../../actions/admin";
import Images from './multimedia/images';
import Videos from './multimedia/videos';
import { adhanTime, iqamaTime } from '../../services/formatDate.js';
const images = [
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png",
    "https://general.eu-central-1.linodeobjects.com/basics/platforms/81805.png"
]
const linkifyYouTubeURLs = ( text ) => {
    var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
    return text?.toString().replace( re, 'http://www.youtube.com/embed/$1' + "?autoplay=1" );
}
const Index = ( { post, somePosts, somePostsManagments, originalPosts, turningOffAds, tvPrayer } ) => {
    const [ mySequence, setMySequence ] = useState( post.length > 0 ? somePosts - 1 : -1 );
    const [ currentCount, setCurrentCount ] = useState( ( post[ mySequence ]?.fileType && post[ mySequence ]?.fileType === "image" ) ? post[ mySequence ]?.image?.length * 20 : 20 )
    const [ dimensions, setdimensions ] = useState( {
        width: 0,
        height: 0
    } )
    useEffect( () => {
        updateWindowDimensions();
        window.addEventListener( 'resize', updateWindowDimensions );
        const intervalAds = setInterval( () => {
            if ( currentCount >= 0 ) {
                setCurrentCount( currentCount - 1 );
            } else {
                turningOffAds( false );
            }
        }, 1000 );

        const intervalAds2 = setInterval( () => {
            if ( adhanTime( tvPrayer.apiPrayerTime ) || iqamaTime( tvPrayer.iqamaTime ) ) {
                turningOffAds( false );
            }
        }, 1000 );

        return () => {
            clearInterval( intervalAds )
            clearInterval( intervalAds2 )
            window.removeEventListener( 'resize', updateWindowDimensions );
        }
    }, [ currentCount ] )


    const updateWindowDimensions = () => {
        setdimensions( { width: window.innerWidth, height: window.innerHeight } );
    }

    useEffect( () => {
        somePostsManagments( post, somePosts, originalPosts )
    }, [] )


    const video = ( post ) => {
        if ( post?.link.length > 0 ) {
            let link = post.link;
            let url, isVideo;
            let query = link[ 0 ];
            let video_id = query.split( "v=" )[ 1 ];
            if ( query.includes( "facebook.com" ) && query.includes( "video" ) ) {
                let regex = '/(videos|story_fbid)(\/|=)(\d+)(\/|&)?/'
                let url2 = link[ 0 ].match( regex );
                url = `https://www.facebook.com/plugins/video.php?href=${ query }/&width=600&height=400&autoPlay=0`;
                isVideo = true;
            } else if ( query.includes( "youtu" ) ) {
                // url = `https://www.youtube.com/embed/${ video_id }?autoPlay=0`;
                url = linkifyYouTubeURLs( link[ 0 ] );

                isVideo = true;

            } else if ( query.includes( "vimeo.com" ) ) {
                const vimeo_id = query.split( "com/" )[ 1 ];
                url = `https://player.vimeo.com/video/${ vimeo_id && vimeo_id }?autoPlay=0`;
                isVideo = true;
            }

            if ( isVideo ) {
                return ( <Videos isVideo={ false } post={ url } /> )
            } else {
                return <h1 style={ {
                    fontSize: "6vw", textAlign: "center", color: "#000", backgroundColor: "#ffffff61",
                } } id="moonode_text_shadow"> { post?.text?.slice( 0, 200 ) }
                    { ( post?.text?.length > 200 ? "le reste sur moonode.com" : " " ) }
                </h1>
            }
        }

    }

    let isImage = post[ mySequence ]?.fileType && post[ mySequence ]?.fileType == "image"
    return (
        <div

            id="myVideo" style={ {
                backgroundImage: isImage ? "" : `url(${ images[ mySequence ] })`,
                backgroundSize: "cover",
                backgroundColor: isImage ? "black" : null,
                top: mySequence == 1 ? "0px" : "",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            } } >
            <span
                id={ `${ !isImage ? "myImage_TV" : " " }` }>
                { ( post[ mySequence ]?.fileType && post[ mySequence ]?.fileType === "image" ) && post[ mySequence ].image.length > 0 ? (
                    <Images post={ post[ mySequence ] } dimensions={ dimensions } />
                ) : <span>
                    { ( post[ mySequence ]?.fileType && post[ mySequence ]?.fileType === "video" ) && post[ mySequence ].video.length > 0 || post[ mySequence ]?.link.length > 0 ? null :
                        <h1 style={ { fontSize: "5vw", textAlign: "center", color: "#000", backgroundColor: "#ffffff61" } } id="moonode_text_shadow"> { post[ mySequence ]?.text.slice( 0, 590 ) }
                            { ( post[ mySequence ]?.text?.length > 590 ? " ..." : " " ) }
                        </h1> }
                </span> }
                { ( post[ mySequence ]?.fileType && post[ mySequence ]?.fileType === "video" ) && post[ mySequence ].video.length > 0 ? (
                    <Videos isVideo={ true } post={ post[ mySequence ] } />
                ) : <span>
                    { video( post[ mySequence ] ) }
                </span> }
            </span>
        </div>
    )
}

const mapStateToProps = state => ( {
    admin: state.admin,
    auth: state.auth
} );

export default connect( mapStateToProps, { somePostsManagments } )( Index );