import React from 'react'
import './style.min.css'
export default function index ( { code, loading } ) {
    return (
        <div style={ { margin: '10%' } }>
            <form className="otc" name="one-time-code" action="#" style={ { marginTop: "10%" } }>
                <fieldset>
                    <legend>Moonode
                        <img src={ require( '../../../styles/img/iconAndroid.png' ) } style={ { height: "auto", width: "10%", marginTop: '-5vh' } } alt="Moonode" />Code</legend>
                    <>
                        { loading ? <div className="loading-bar">
                            <div className="loading-bar-progress"></div>
                        </div> :
                            <div id="generic_number">
                                <input value={ code[ 0 ] } inputtype="" autoComplete="one-time-code" id="otc-1" disabled />
                                <input maxLength="1" value={ code[ 1 ] } id="otc-2" disabled />
                                <input maxLength="1" value={ code[ 2 ] } id="otc-3" disabled />
                                <input maxLength="1" value={ code[ 3 ] } id="otc-4" disabled />
                                <input maxLength="1" value={ code[ 4 ] } id="otc-5" disabled />
                                <input maxLength="1" value={ code[ 5 ] } id="otc-6" disabled />
                            </div> }
                    </>
                    <span>
                    </span>
                </fieldset >
                <div className="alert" role="alert">
                    <img src={ require( '../../../styles/img/qr.png' ) } style={ { height: "auto", width: "30%", marginTop: '5vh', marginLeft: '33%' } } alt="Moonode" />
                    <br />
                    <br />
                    <h5 style={ { marginLeft: '5%' } }>
                        <strong>Download</strong>  the Moonode application, <br />  input the code below to get  redirected
                    </h5>
                </div>
            </form >
        </div >

    )
}
