import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import GenericComponent from '../../views/wrappers/generic/index';
import { getDigits, setDigits } from "../../actions/admin";
import { getCodeFromStorage } from "../../services/apiService.js";
export const Index = ( { getDigits, setDigits } ) => {
    const [ localCode, setCode ] = useState( getCodeFromStorage() )
    const [ localLoading, setLoading ] = useState( true )

    useEffect( () => {
        let intervalAds;

        const fetchAndHandleCode = async () => {
            try {
                let code = getCodeFromStorage()
                if ( !code || code === 'NONONO' ) {
                    let newCode = await getDigits( code );
                    if ( newCode ) {
                        setCode( newCode );
                        code = newCode;
                    }
                }

                setLoading( false );

                let res = await setDigits( code );
                if ( res && res.tvCode && res.tvCode.active ) {
                    window.location.replace( res.tvCode.defaultUrl );
                } else {
                    if ( !intervalAds ) {
                        intervalAds = setInterval( fetchAndHandleCode, 5000 );
                    }
                }
            } catch ( error ) {
            }
        };

        fetchAndHandleCode(); // Initially invoke the function

        return () => {
            if ( intervalAds ) clearInterval( intervalAds ); // Clean up the interval on component unmount
        };
    }, [] ); // Empty dependency array means this effect runs once on mount

    return (
        <GenericComponent code={ localCode } loading={ localLoading } expired={ true } />
    )
}

const mapStateToProps = ( state ) => ( {
    tv: state.tv,
} )

export default connect( mapStateToProps, { getDigits, setDigits } )( Index )
