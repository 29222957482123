import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextFadeComponent from '../../../../layouts/TitleComponent';
import { convertTimeWorld } from '../../../../services/formatDate';
import GlassedBox from '../../../../layouts/glassmorphism/boxGlassed';
import { getTvSettings, getPrayerTime } from '../../../../services/selectors/prayersSelectors';

const JumuaCarousel = () => {
    const { t } = useTranslation();
    const tvSettings = useSelector( getTvSettings );
    const prayerTime = useSelector( getPrayerTime );
    const [ activeIndex, setActiveIndex ] = useState( 0 );
    const [ jumuaTimes, setJumuaTimes ] = useState( [] );

    useEffect( () => {
        const times = [];
        try {
            if ( prayerTime?.Jumua ) times.push( { time: prayerTime.Jumua, lang: prayerTime.JumuaLang } );
            if ( prayerTime?.toggleJumua ) times.push( { time: prayerTime.Jumua2, lang: prayerTime.Jumua2Lang } );
            if ( prayerTime?.toggleJumua2 ) times.push( { time: prayerTime.Jumua3, lang: prayerTime.Jumua3Lang } );
            if ( prayerTime?.toggleJumua3 ) times.push( { time: prayerTime.Jumua4, lang: prayerTime.Jumua4Lang } );

            setJumuaTimes( times );
        } catch ( error ) {
            times.push( { time: 'N/A', lang: "" } );
            setJumuaTimes( times );
        }
    }, [ prayerTime ] );

    useEffect( () => {
        const interval = setInterval( () => {
            setActiveIndex( prevIndex => ( prevIndex + 1 ) % jumuaTimes.length );
            //do not change before swiper and after date.
        }, 57000 );

        return () => clearInterval( interval );
    }, [ jumuaTimes ] );

    return (
        <GlassedBox
            gmode={ 'gm-dmode' }
            styles={ {
                margin: '0.5vh',
                width: '30%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '2vh',
            } }
        >
            <Typography
                className="jbx-text"
                component="span"
                sx={ {
                    fontSize: { xs: '3vw', sm: '3vw', md: '3vw', lg: '3vw', xl: '3vw' },
                    overflow: 'hidden',
                    marginBottom: '-2vw',
                    marginTop: '1vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                } }
            >

                <TextFadeComponent text={ t( 'salat.jumua' ) + " " + ( activeIndex > 0 ? activeIndex + 1 : '' ) } />
            </Typography>
            { jumuaTimes.length > 0 && (
                <div className="kfi383sszn">
                    <Typography component="p">
                        { convertTimeWorld( tvSettings?.isTimeConvention, jumuaTimes[ activeIndex ]?.time ) }
                        <span>{ jumuaTimes[ activeIndex ].lang }</span>
                    </Typography>
                </div>
            ) }
        </GlassedBox>
    );
};

// export with memo
export default React.memo( JumuaCarousel );
