import React from 'react';
import Adhan from './Adhan';
import Iqama from './Iqama';

const PrayerRoumi = ( { children, tvPrayer, arabic, calculeNextIqama, iqama, langs, color, isArabicFont } ) => {
    return (
        <div className="appLayout">
            <div className="prayerTimesContainer">
                <div className="container-x"
                    style={ { marginBottom: "-1.7vh" } }
                >
                    { [ 'isha', 'maghrib', 'asr', 'dhuhr', 'fajr', 'salat' ].map( ( prayer, index ) => (
                        <div className="title-prayers" key={ index }
                            id="xmoxshadow"
                            style={ {
                                flex: '1',
                                opacity: "30%",
                                margin: '1vh',
                                marginRight: '1.5vh',
                                marginLeft: '1.5vh',
                                borderRadius: '2vh'
                            } }
                        >
                            <div className="content">
                                <h1 className={ `${ isArabicFont ? 'arabic' : 'rumi' }` }
                                    style={ {
                                        marginTop: '3.5vh',
                                        fontSize: "2.5vw",
                                        textAlign: "center",
                                        fontWeight: 500,
                                        color: "#3a3a3a"
                                    } }
                                >
                                    { arabic ? langs[ prayer ][ Number( tvPrayer.tvLangsOne ) ] : langs[ prayer ][ Number( tvPrayer.tvLangsTwo ) ] }
                                </h1>
                            </div>
                        </div>
                    ) ) }
                </div>
                <Adhan tvPrayer={ tvPrayer } isArabicFont={ isArabicFont } arabic={ arabic } calculeNextIqama={ calculeNextIqama } iqama={ iqama } langs={ langs } color={ color } />
                <Iqama tvPrayer={ tvPrayer } isArabicFont={ isArabicFont } arabic={ arabic } calculeNextIqama={ calculeNextIqama } iqama={ iqama } langs={ langs } color={ color } />
                <span style={ { marginBottom: "3vh" } } />
                { children }
            </div>
        </div>
    );
};

export default PrayerRoumi;
