import React from 'react';
import { Box, Typography } from '@mui/material';

function Index ( { prayers } ) {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        width: "30%",
        border: "none",
        boxShadow: "none",
    };

    const middleBoxStyle = ( backgroundImage, paddingTop ) => ( {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingTop: paddingTop,
        margin: "5px",
        width: "100%",
        height: prayers?.toggleEid && prayers?.toggleEid2 ? "48%" : "100%",
        backgroundImage: `url(${ backgroundImage })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        borderRadius: "2vh",

    } );

    const textStyle = {
        alignContent: "center",
        alignSelf: "center",
        textAlign: "center",
    };

    return (
        <Box
            className='gm-dmode'
            id="xmoxshadow"
            style={ containerStyle }>
            { prayers?.toggleEid && (
                <Box sx={ middleBoxStyle( require( './eid.png' ), !prayers?.toggleEid2 ? "10%" : "10%" ) }>
                    <Typography sx={ { ...textStyle, fontSize: "3.7vw", fontWeight: "600" } }>{ prayers?.Eid }</Typography>
                    <Typography sx={ { ...textStyle, fontSize: "3.2vh" } }>Eid</Typography>
                </Box>
            ) }
            { prayers?.toggleEid2 && (
                <Box sx={ middleBoxStyle( require( './eid.png' ), "10%" ) }>
                    <Typography sx={ { ...textStyle, fontSize: "3.7vw", fontWeight: "600" } }>{ prayers?.Eid2 }</Typography>
                    <Typography sx={ { ...textStyle, fontSize: "3.2vh" } }>Eid 2</Typography>
                </Box>
            ) }
        </Box>
    );
}

export default Index;
