import React, { useContext } from 'react';
import logo from '../../styles/img/logo.png'
import Tooltip from '@mui/material/Tooltip';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { SocketContext } from '../../context/socket';
import useNavigatorOnLine from '../../hooks/onlineStatus';

const Footer = ( { isLandscape = false, screenName = 'DinScreen', dinscreen = true } ) => {
    const { isConnected } = useContext( SocketContext );
    const code = localStorage.getItem( 'code' );
    const online = useNavigatorOnLine()
    const todayVersion = "• 2024 August 10 version ";
    return (
        <Box
            sx={ {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '0.5vw',
            } }
        >
            <Box sx={ { display: 'flex' } }>
                <Box
                    component="img"
                    src={ logo }
                    alt="Logo"
                    sx={ {
                        marginLeft: '1.5vh',
                        marginRight: '1.4vh',
                        marginTop: '0.3vh',
                        maxWidth: '3.5vw',
                        maxHeight: '1.2vw',
                        objectFit: 'contain',
                        borderRadius: '50%'
                    } }
                />
                <Typography
                    sx={ {
                        fontSize: {
                            xs: "1.8vh",
                            sm: "1.8vh",
                            md: "1.8vh",
                        },
                    } }
                >
                    Moonode.com   { code ? '• ' : "" }{ code ?? '' }
                </Typography>

            </Box>

            {/* Socket Status Indicator */ }
            <Box sx={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                <Tooltip title={ isConnected ? "Insta Change On" : "Insta Change Off" }>
                    { dinscreen ? <IconButton sx={ {
                        alignItems: 'center', justifyContent: 'center', display: 'flex',
                        marginTop: {
                            sm: '-0.5vw',
                            xs: '-1.5vw',
                            sm: '-1.2vw',
                            md: '-0.8vw',
                            lg: '-0.8vw',
                            xl: '-0.5vw',
                        }
                    } }>
                        { isConnected ?
                            <i className="material-icons" style={ {

                                color: '#FABF0F',
                                textShadow: '0 0 2px #000000, 0 0 2px #000000',
                                fontSize: '3vh',

                            } }>bolt</i> : <i className="material-icons" style={ {
                                color: '#fff',
                                textShadow: '0 0 2px #000000, 0 0 2px #000000',
                                fontSize: '3vh',
                            } }>bolt</i> }
                    </IconButton> : <span></span> }
                </Tooltip>
            </Box>
            <Typography
                sx={ {
                    marginRight: '2vh',
                    fontSize: {
                        xs: "1.8vh",
                        sm: "1.8vh",
                        md: "1.8vh",
                    }
                } }>
                © { screenName } { isLandscape ? todayVersion : "" }

                { online ? "" : <Tooltip title={ online ? "" : "Tv Offline" }>🔴</Tooltip> }
            </Typography>
        </Box>

    );
};

export default React.memo( Footer );

