import React, { useEffect, useCallback } from 'react';
import { togglePlay } from '../../../actions/player';
import { useSelector, useDispatch } from 'react-redux';
import { updatePrayerStatus } from '../../../services/app/prayers/states';
import {
    getPlaying,
    getPlaylistLength,
    getCurrentMediaIndex
} from '../../../services/selectors/playerSelectors';
import {
    getDarkModeSetting,
    getAdhanAudioMode,
    getFocusModeTimes,
    getApiPrayerTime,
    getIqamaTime,
    getIsIqama,
} from '../../../services/selectors/prayersSelectors';

const StateHandlerWrapper = ( { children } ) => {
    const dispatch = useDispatch();

    const currentMediaIndex = useSelector( getCurrentMediaIndex );
    const darkModeStting = useSelector( getDarkModeSetting );
    const focusModeTimes = useSelector( getFocusModeTimes );
    const apiPrayerTime = useSelector( getApiPrayerTime );
    const playlistLength = useSelector( getPlaylistLength );
    const adhanMode = useSelector( getAdhanAudioMode );
    const iqamaTime = useSelector( getIqamaTime );
    const isIqama = useSelector( getIsIqama );
    const playing = useSelector( getPlaying );

    const getPlayTimerDuration = () => {
        try {
            const urlParams = new URLSearchParams( window.location.search );
            const timerParam = urlParams.get( 'playertimer' );
            let playTimerDuration = parseInt( timerParam, 10 );

            if ( Number.isInteger( playTimerDuration ) && playTimerDuration > 0 && playTimerDuration <= 60 ) {
                return playTimerDuration;
            }

            return 2;
        } catch ( error ) {
            return 2;
        }
    };

    const memoizedUpdatePrayerStatus = useCallback( () => {
        updatePrayerStatus( dispatch, apiPrayerTime, iqamaTime, isIqama, darkModeStting, focusModeTimes, adhanMode );
    }, [ dispatch, apiPrayerTime, iqamaTime, focusModeTimes ] );

    useEffect( () => {
        const playTimerDuration = getPlayTimerDuration();
        const playTimer = setTimeout( () => {
            if ( !playing && playlistLength > 0 ) {
                dispatch( togglePlay( true ) );
            }
        }, playTimerDuration * 60 * 1000 );
        return () => clearTimeout( playTimer );
    }, [ playing, currentMediaIndex, dispatch ] );

    useEffect( () => {
        const interval = setInterval( () => {
            memoizedUpdatePrayerStatus();
        }, 1000 );
        return () => clearInterval( interval );
    }, [ memoizedUpdatePrayerStatus ] );

    return <>{ children }</>;
};

export default React.memo( StateHandlerWrapper );
