import React, { useState, useEffect, useMemo, memo } from 'react';
import GlassedBox from '../../glassmorphism/boxGlassed'; // Adjust the import path as needed
import { useTheme } from '@mui/material';
import '../hashed.min.css';
import dayjs from 'dayjs';

import {
    getTvSettings,
} from '../../../services/selectors/prayersSelectors';

import { useSelector } from 'react-redux';

const GlassMorphismClock = ( { height = '55%', customFont = false, customFontAddon } ) => {

    const tvSettings = useSelector( getTvSettings );
    const [ time, setTime ] = useState( dayjs() );
    const theme = useTheme();

    useEffect( () => {
        const timer = setInterval( () => {
            setTime( dayjs() );
        }, 1000 );
        return () => clearInterval( timer );
    }, [] );

    const { formattedHours, minutes, seconds, period } = useMemo( () => {
        const format = tvSettings?.isTimeConvention ? 'hh' : 'HH'; // Use 'hh' for 12-hour and 'HH' for 24-hour format
        return {
            formattedHours: time.format( format ),
            minutes: time.format( 'mm' ),
            // seconds: time.format( 'ss' ),
            period: time.format( 'A' ),
        };
    }, [ time, tvSettings ] );

    const getClassName = ( baseClass ) => `${ baseClass }${ customFont ? customFontAddon : '' }`;

    const boxStyle = {
        height: height,
        borderRadius: '2vh',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: customFont ? ' ' : '1.5vw',
        fontFamily: theme?.typography?.fontFamily,
        fontSize: theme?.typography?.fontSize,
        fontWeight: theme?.typography?.body1.fontWeight,
    };

    return (
        <GlassedBox
            gmode={ customFont ? 'gm-dmode' : '' }
            styles={ boxStyle }
        >
            <div className="x834gf">
                <div className="q8jxk5">
                    <span className={ getClassName( "v9l3qd" ) } style={ { fontWeight: theme?.typography?.body1.fontWeight } }>
                        { formattedHours }
                    </span>
                    <span className={ getClassName( "h4j5fr" ) }>:</span>
                    <span className={ getClassName( "b2v8eu" ) } style={ { fontWeight: theme?.typography?.body1.fontWeight } }>
                        { minutes }
                    </span>
                    <div className={ getClassName( "k1q2tb" ) } style={ { marginLeft: customFont ? '2vh' : 'none' } }>
                        <span className={ getClassName( "z8x9fo" ) }>{ period?.charAt( 0 ) }</span>
                        <span className={ getClassName( "n3q4m2" ) }>{ period?.charAt( 1 ) }</span>
                    </div>
                </div>
            </div>
        </GlassedBox>
    );
};

export default memo( GlassMorphismClock );
