export const DATA = function () {
    return {
        "shuruk": [ "Shuruk", "الشروق", "Sunrise", "طلوع آفتاب" ],
        "midnight": [ "Minuit", "منتصف الليل", "Midnight", "آدھی رات" ],
        "thirdnight": [ "Tiers Nuit", "ثلث الليل", "Thirds Night", "تیسری رات" ],
        "fajr": [ "Fajr", "الفجر", "Fajr", "فجر" ],
        "fajrIn": [ "Adhan Fajr dans: ", "أذان الفجر بعد: ", "Fajr adhan in: ", "فجردان میں " ],
        "dhuhrIn": [ "Adhan Dhuhr dans: ", "أذان الظهر بعد: ", "Dhuhr adhan in: ", "ظہر کی اذان میں " ],
        "asrIn": [ "Adhan Asr dans: ", "أذان العصر بعد: ", "Asr adhan in: ", "میں فجر " ],
        "maghribIn": [ "Adhan Maghrib dans: ", "أذان المغرب بعد: ", "Maghrib adhan in: ", "عصر کی اذان میں " ],
        "ishaIn": [ "Adhan Isha dans: ", "أذان العشاء بعد: ", "Isha adhan in: ", "عشاء کی اذان میں " ],
        "iqamaFajrIn": [ "Iqama Fajr dans: ", "إقامة الفجر بعد: ", "Fajr iqama in: ", "فجر کی اقامت " ],
        "iqamaDhuhrIn": [ "Iqama Dhuhr dans: ", "إقامة الظهر بعد: ", "Dhuhr iqama in: ", "ظہر اقامہ میں " ],
        "iqamaAsrIn": [ "Iqama Asr dans: ", "إقامة العصر بعد: ", "Asr iqama in: ", "عصر کی اقامت " ],
        "iqamaMaghribIn": [ "Iqama Maghrib dans: ", "إقامة المغرب بعد: ", "Maghrib iqama in: ", "مغرب کی اقامت " ],
        "iqamaIshaIn": [ "Iqama Isha dans: ", "إقامة العشاء بعد: ", "Isha iqama in: ", "عشاء کا اقامہ " ],
        "in": [ " dans ", " بعد ", " in ", "میں " ],
        "dhuhr": [ "Dhuhr", "الظهر", "Dhuhr", "ظهر" ],
        "asr": [ "Asr", "العصر", "Asr", "عصر" ],
        "maghrib": [ "Maghrib", "المغرب", "Maghrib", "مغرب" ],
        "isha": [ "Isha", "العشاء", "Isha", "عشاء" ],
        "jumua": [ "Jumua", "الجمعة", "Jumua", "جمعہ" ],
        "jumua2": [ "2eme Jumua", "الجمعة الثانية", "Second Jumua", "دوسرا جمعہ" ],
        "jumua3": [ "3eme Jumua", "الخطبة الثالثة", "Third Jumua", "تیسرا خطبہ " ],
        "jumua4": [ "4eme Jumua", "الخطبة الرابعة", "Fourth Jumua", "چوتھا خطبہ" ],
        "salat": [ "Prière", "صلاة", "Prayer", "صلاة" ],
        "adhan": [ "ADHAN", "الأذان", "ADHAN", "اذان" ],
        "iqama": [ "IQAMA", "الإقامة", "IQAMA", "إقامة" ],
        "mosque": [ "Mosquée", "مسجد", "Mosque", "مسجد" ],
        "activity": [ "Activité", "نشاط", "Activity", "دوسرے" ],
        "imam": [ "Imam", "إمام", "Imam", "امام" ],
        "hours": [ " Heures ", " ساعات ", " Hours ", " گھنٹے " ],
        "minutes": [ "m ", "د ", "m ", " م " ],
        "h": [ "h", "س", "h", "س" ]

    }
}


export const containsArabic = function ( text ) {
    try {
        return /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test( text );
    } catch ( e ) {
        return false;
    }
}
