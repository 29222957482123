import React, { Suspense } from 'react';
import { getApiPrayerTime } from '../../../../../services/selectors/prayersSelectors';
import TextFadeComponent from '../../../../../layouts/TitleComponent/index';
import GlassedBox from '../../../../../layouts/glassmorphism/boxGlassed';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import '../kvbt3e.min.css';

const SkeletonLoader = React.lazy( () => import( '../../../../../layouts/skeleton' ) );

const Shouruq = () => {
    const { t } = useTranslation();
    const apiPrayerTime = useSelector( getApiPrayerTime );

    const glassedBoxStyles = {
        width: '33.33%',
        borderRadius: '2vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '2.5vh',
        marginLeft: '1.5vh',
        overflow: 'hidden',
        textAlign: 'center',  // Add textAlign to ensure proper centering
    };

    const typographyStyles = {
        fontSize: {
            xs: '3.5vh',
            sm: '3.5vh',
            md: '3.5vh',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center', //
    };

    const spanStyles = {
        margin: '2vh',
        fontSize: {
            xs: '4.5vh',
            sm: '4.5vh',
            md: '4.5vh',
        },
    };

    return (
        <GlassedBox gmode='gm-dmode' styles={ glassedBoxStyles }>
            { apiPrayerTime ? (
                <Box sx={ typographyStyles }>
                    <TextFadeComponent text={ t( 'salat.shuruq' ) } textStyle={ typographyStyles } />
                    <Typography sx={ spanStyles }>
                        { apiPrayerTime?.Sunrise }
                    </Typography>
                </Box>
            ) : (
                <SkeletonLoader />
            ) }
        </GlassedBox>
    );
};

export default Shouruq;
