import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAcceptPortrait } from '../services/selectors/prayersSelectors';

const getOrientation = () => ( {
    angle: window.screen.orientation.angle,
    type: window.screen.orientation.type
} );

export const useOrientation = () => {
    const [ orientation, setOrientation ] = useState( getOrientation() );
    const acceptPortrait = useSelector( getAcceptPortrait ); // Use the selector here

    useEffect( () => {
        const handleOrientationChange = () => {
            setOrientation( getOrientation() );
        };

        window.addEventListener( 'orientationchange', handleOrientationChange );

        return () => {
            window.removeEventListener( 'orientationchange', handleOrientationChange );
        };
    }, [] );

    return { orientation, acceptPortrait };
};