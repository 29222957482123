import { createSelector } from 'reselect';

// Base selector for accessing the player state slice
const getPlayer = state => state.player;

// Memoized selectors for each property in the player state
export const getPlaying = createSelector(
    [ getPlayer ],
    player => player.playing
);

export const getIsExternalControlEnabled = createSelector(
    [ getPlayer ],
    player => player.isExternalControlEnabled
);

export const getCurrentMediaType = createSelector(
    [ getPlayer ],
    player => player.currentMediaType
);

export const getCurrentMediaIndex = createSelector(
    [ getPlayer ],
    player => player.currentMediaIndex
);

export const getKeepPlaying = createSelector(
    [ getPlayer ],
    player => player.keepPlaying
);

export const getPlaylist = createSelector(
    [ getPlayer ],
    player => player.playlist
);

export const getPlaylistLength = createSelector(
    [ getPlayer ],
    player => player.playlist.length
);

export const getVolume = createSelector(
    [ getPlayer ],
    player => player.volume
);

export const getMuted = createSelector(
    [ getPlayer ],
    player => player.muted
);

export const getLength = createSelector(
    [ getPlayer ],
    player => player.length
);

// New selectors for current media and comments
export const getCurrentMedia = createSelector(
    [ getPlaylist, getCurrentMediaIndex ],
    ( playlist, index ) => playlist[ index ] || {}
);

export const getCurrentComments = createSelector(
    [ getCurrentMedia ],
    currentMedia => currentMedia.comments || []
);

export const getCurrentMediaDuration = createSelector(
    [ getCurrentMedia ],
    currentMedia => currentMedia.duration || 0
);

export const getCurrentMediaText = createSelector(
    [ getCurrentMedia ],
    currentMedia => currentMedia.text || ''
);

export const getCurrentMediaTypeArray = createSelector(
    [ getCurrentMedia ],
    currentMedia => currentMedia.media || []
);

// Selector to get the current post or comment and whether the parent post is a thread
export const getActualPost = createSelector(
    [ getPlayer ],
    player => {
        if ( player.playlist.length === 0 ) {
            return {
                actualPost: null,
                parentIsThread: false
            };
        }
        const currentPost = player.playlist[ player.currentMediaIndex ];
        const actualPost = player.currentCommentIndex >= 0 && currentPost.comments
            ? currentPost.comments[ player.currentCommentIndex ]
            : currentPost;

        return {
            actualPost,
            parentIsThread: player.isThread
        };
    }
);

export const getTypeAudio = createSelector(
    [ getPlayer ],
    player => player.type_AUDIO
);

export const getTypeVideo = createSelector(
    [ getPlayer ],
    player => player.type_VIDEO
);

export const getTypeText = createSelector(
    [ getPlayer ],
    player => player.type_TEXT
);

export const getTypeImage = createSelector(
    [ getPlayer ],
    player => player.type_IMAGE
);