import React, { Suspense, lazy, useEffect, useState } from "react";
import { Box } from "@mui/material";
import '../../../styles/flyer.min.css'
import { useSelector } from "react-redux";
const LazyTopContainer = lazy( () => import( "../../../layouts/trioFrame/TopContainer" ) );
const LazyBottomContainer = lazy( () => import( "../../../layouts/trioFrame/BottomContainer" ) );

const dummyBusinessCards = [
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/businessCard1.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/businessCard2.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/businessCard3.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/businessCard4.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/businessCard5.png",
];

const dummyPosters = [
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster1.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster1-ar.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster2.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster2-ar.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster3.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster3-ar.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster4.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster4-ar.png",
    "https://flyerscreen.eu-central-1.linodeobjects.com/basics/placeholders/poster5.png",
];

const dummyAnnouncements = [
    "اللهم أنت الشافي المعافي، اشفي أمي شفاء لا يغادر سقمًا، وخفف عنها كل ما يؤلمها يا كريم.",
    "O my Lord! have compassion on them, as they brought me up (when I was) a child",
    "Ô Seigneur, pardonne-moi ainsi qu’à mes parents, et fais-leur miséricorde à tous deux comme ils m’ont élevé lorsque j’étais petit",
    "May Allah's peace, mercy, and blessings be upon you.",
    "In the remembrance of Allah do hearts find rest.",
];

const Home = ( { children } ) => {
    //use selector
    const { data } = useSelector( state => state.flyer );

    const [ processedData, setProcessedData ] = useState( {
        businessCards: dummyBusinessCards,
        posters: dummyPosters,
        announcements: dummyAnnouncements,
    } );

    useEffect( () => {
        const advertisments = data?.screen?.advertisments;
        const isAdvertismentsArray = Array.isArray( advertisments );

        const businessCards = isAdvertismentsArray
            ? advertisments
                .filter( ( ad ) => ad.category === "businessCard" )
                .map( ( ad ) => ad.media )
            : dummyBusinessCards;

        const posters = isAdvertismentsArray
            ? advertisments
                .filter( ( ad ) => ad.category === "poster" )
                .map( ( ad ) => ad.media )
            : dummyPosters;

        const announcements = isAdvertismentsArray
            ? advertisments
                .filter( ( ad ) => ad.category === "announcement" )
                .map( ( ad ) => ad.announcement )
            : dummyAnnouncements;

        setProcessedData( {
            businessCards:
                businessCards && businessCards.length > 0
                    ? businessCards
                    : dummyBusinessCards,
            posters: posters && posters.length > 0 ? posters : dummyPosters,
            announcements:
                announcements && announcements.length > 0
                    ? announcements
                    : dummyAnnouncements,
        } );
    }, [ data ] );
    return (
        <Box
            className={ `parent-box` }
            sx={ {
                height: "102vh",  // Changed from 102vh to 100vh to fit the viewport height
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                backgroundImage: `url("https://dinscreen.eu-central-1.linodeobjects.com/default/basics/mn-blue.png")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                paddingBottom: '0vh',  // Adjust this value to match the height of your footer
                overflow: "auto"  // Added to enable scrolling if content exceeds viewport height
            } }
        >
            <Suspense fallback={ <></> }>
                <LazyTopContainer data={ processedData } />
                <LazyBottomContainer
                    data={ processedData.businessCards }
                    qrCode={ data?.screen?.qrCode }
                />
                <Box sx={ {
                    marginTop: '-1vh'
                } }>
                    { children }
                </Box>
            </Suspense>
        </Box>
    );
};
export default Home;
