import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MobileGlassy from './pages/glassy/mobile';
import FlyerScreen from './pages/flyerScreen';
import Index from './pages/prayers/modern';
import NotFound from './pages/notFound';
import { Provider } from "react-redux";
import Landing from './pages/landing';
import Generic from './pages/generic';
import Glassy from './pages/glassy';
import './styles/main.min.css'
import store from "./store";
import './i18n';

const ModernWraper = () => <Index ads={ false } />;

function App () {
  return (
    <Provider store={ store }>
      <Router>
        <Routes>
          <Route path="/use/generic" element={ <Generic /> } />
          <Route path="/" element={ <Landing /> } />
          <Route path="/glassy/:token" element={ <Glassy /> } />
          <Route path="/mobile/glassy/:token" element={ <MobileGlassy /> } />
          <Route path="/:digits/trioframe" element={ <FlyerScreen /> } />
          <Route path="/noads/:token" element={ <ModernWraper /> } />
          <Route path="/:token" element={ <Index /> } />
          <Route path="/:token/view/1" element={ <Index /> } />
          <Route path="/:token/view/1/:time" element={ <Index /> } />
          <Route path="/:token/view/1/:time/:reload" element={ <Index /> } />
          <Route path="/:token/view/2" element={ <Index /> } />
          <Route path="/404" element={ <NotFound /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
