import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePrayerTimeState from '../../../../../../hooks/prayerState';
import TitleComponent from '../../../../../../layouts/TitleComponent';
import GlassedBox from '../../../../../../layouts/glassmorphism/boxGlassed';

import {
    getTomorrowIqamaTime,
    getHighlighted,
    getIqamaTime,
    getIsIqama
} from '../../../../../../services/selectors/prayersSelectors';

import './tltx.min.css';

const SalatTitleBox = () => {
    const { t } = useTranslation();
    const iqamaTime = useSelector( getIqamaTime );
    const tomorrowIqamaTime = useSelector( getTomorrowIqamaTime );
    const highlighted = useSelector( getHighlighted );
    const isIqama = useSelector( getIsIqama );

    const state = usePrayerTimeState( iqamaTime, tomorrowIqamaTime );
    const { f, d, a, m, i } = highlighted;

    const getPrayerTimes = useCallback( () => [
        { key: 'isha', state: state.Isha, diff: ( state.iDiff && i && isIqama ) },
        { key: 'maghrib', state: state.Maghrib, diff: ( state.mDiff && m && isIqama ) },
        { key: 'asr', state: state.Asr, diff: ( state.aDiff && a && isIqama ) },
        { key: 'dhuhr', state: state.Dhuhr, diff: ( state.dDiff && d && isIqama ) },
        { key: 'fajr', state: state.Fajr, diff: ( state.fDiff && f && isIqama ) },
    ], [ state, f, d, a, m, i ] );

    const prayerTimes = getPrayerTimes();

    const boxStyles = {
        height: isIqama ? '20%' : '35%',
        display: 'flex',
        fontSize: { xs: '2.5vw', sm: '2.5vw', md: '2.5vw', lg: '2.5vw', xl: '2vw' },
    };

    const glassedBoxStyles = ( isActive, diff ) => ( {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        backgroundColor: isActive ? '#fff' : '',
        ...( isActive ? { gmode: 'gm-xmode321s' } : ( diff ? { gmode: 'gm-lmod5431s' } : {} ) ),
    } );

    return (
        <Box sx={ boxStyles }>
            { prayerTimes.map( ( prayer ) => (
                <GlassedBox
                    key={ prayer.key }
                    gmode={ glassedBoxStyles( prayer.state, prayer.diff ).gmode }
                    styles={ glassedBoxStyles( prayer.state, prayer.diff ) }
                >
                    <TitleComponent text={ t( `salat.${ prayer.key }` ) } isTomorrow={ prayer.diff } textStyle={ { marginTop: prayer.state ? "-0.2vw" : "" } } />
                </GlassedBox>
            ) ) }
            <GlassedBox styles={ { flex: 1 } }>
                <TitleComponent text={ t( 'salat.salawat' ) } />
            </GlassedBox>
        </Box>
    );
};

export default React.memo( SalatTitleBox );
