import React from 'react'
import { convertTimeWorld } from '../../../services/formatDate.js';

const Jumua = ( { arabic, tvPrayer, langs, color, textColor, fontWeight, isTimeConvention, isArabicFont } ) => {

    return (
        tvPrayer.prayerTime.toggleJumua2 ? <div className={ `middle-box-3 ${ ( color ) }` } id="xmoxshadow" >
            { !tvPrayer.prayerTime.toggleJumua ? <span><br /><br /> </span> : <span><br /></span> }
            { arabic ? <span style={ { marginTop: "1vw" } }>
                <h1 className={ ` ${ isArabicFont ? "arabic" : "" }` } style={ { fontSize: "2.8vw", fontWeight: fontWeight, marginTop: "1vw", textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                    { langs.jumua[ Number( tvPrayer.tvLangsOne ) ] } { " " }
                </h1>
                <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>

                    { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua ) }
                </h1>
            </span> : <span>
                <h1 className={ ` ${ isArabicFont ? "arabic" : "" }` } style={ { fontSize: "2.8vw", fontWeight: fontWeight, marginTop: "1vw", textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                    { langs.jumua[ Number( tvPrayer.tvLangsOne ) ] + " 3" } { " " }
                </h1>
                <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>
                    { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua3 ) }
                </h1>
            </span> }
            { arabic ? <span >
                <h1 className={ ` ${ isArabicFont ? "arabic" : "" }` } style={ { fontSize: "2.8vw", fontWeight: fontWeight, marginTop: "1vw", textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                    { langs.jumua2[ Number( tvPrayer.tvLangsOne ) ] } { " " }
                </h1>

                <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>
                    { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua2 ) }
                </h1>
            </span> : tvPrayer.prayerTime.toggleJumua3 && <span>
                <h1 className={ ` ${ isArabicFont ? "arabic" : "" }` } style={ { fontSize: "2.8vw", fontWeight: fontWeight, textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                    { langs.jumua[ Number( tvPrayer.tvLangsOne ) ] + " 4" } { " " }
                </h1>
                <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>
                    { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua4 ) }
                </h1>
            </span> }
        </div> :
            <div className={ `middle-box-3 ${ ( color ) }` } id="xmoxshadow">
                { !tvPrayer.prayerTime.toggleJumua ? <span><br /><br /> </span> : <span><br /></span> }
                <span>
                    <h1 className={ `${ isArabicFont ? "arabic" : "" }` }
                        style={ { fontSize: "2.8vw", fontWeight: fontWeight, marginTop: "1vw", textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                        { arabic ? langs.jumua[ Number( tvPrayer.tvLangsOne ) ] : langs.jumua[ Number( tvPrayer.tvLangsTwo ) ] } { " " }
                    </h1>

                    <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>
                        { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua ) }
                    </h1>
                </span>

                { tvPrayer.prayerTime.toggleJumua ?
                    <span>
                        <h1 className={ ` ${ isArabicFont ? "arabic" : "" }` } style={ { fontSize: "2.8vw", fontWeight: fontWeight, marginTop: "1vw", textAlign: "center", lineHeight: "0.4", color: textColor, maxHeight: "320px" } }>
                            { arabic ? langs.jumua2[ Number( tvPrayer.tvLangsOne ) ] : langs.jumua2[ Number( tvPrayer.tvLangsTwo ) ] } { " " }
                        </h1>
                        <h1 style={ { fontSize: "4.7vw", fontWeight: fontWeight, textAlign: "center", color: "#fff" } }>
                            { convertTimeWorld( isTimeConvention, tvPrayer.prayerTime.Jumua2 ) }
                        </h1>
                    </span>
                    : null }

            </div>
    )
}

export default Jumua
