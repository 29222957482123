import React, { lazy, PureComponent, Suspense } from 'react';

import { getTvLangsOne, getTvLangsTwo, getEntity, getForceWhite, getFontSize, getBackgroundImage, getFontWeigth } from '../services/selectors/prayersSelectors';
import { getDarkMode, getFocusMode } from '../services/selectors/appStatusSelectors';

import { getCurrentLanguage } from '../services/selectors/languageSelectors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { changeLanguage } from '../actions/language';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import SEO from '../services/SEO';

const Footer = lazy( () => import( '../layouts/footer' ) );

class ApplicationSettings extends PureComponent {

    constructor( props ) {
        super( props );
        this.languageMappings = [ 'fr', 'ar', 'en', 'ur', 'bn', 'ja' ];
    }

    componentDidMount () {
        this.changeLangInterval = setInterval( this.changeLanguage, 210000 );
    }

    componentWillUnmount () {
        clearInterval( this.changeLangInterval );
    }

    handleFullscreen = () => {
        try {
            if ( !document.fullscreenElement ) {
                document.documentElement.requestFullscreen().catch( ( err ) => {
                    console.error( " ", err );
                } );
            } else {
                document.exitFullscreen().catch( ( err ) => {
                    console.error( " ", err );
                } );
            }
        } catch ( error ) {
            console.error( "", error );
        }
    };

    changeLanguage = () => {
        const { currentLanguage, tvLangsOne, tvLangsTwo, dispatch, i18n } = this.props;
        const currentIndex = this.languageMappings.indexOf( currentLanguage );
        const nextIndex = currentIndex === Number( tvLangsOne ) ? Number( tvLangsTwo ) : Number( tvLangsOne );
        const newLang = this.languageMappings[ nextIndex ];
        if ( newLang !== currentLanguage ) {
            dispatch( changeLanguage( newLang ) );
            i18n.changeLanguage( newLang );
        }
    };

    createTheme = ( darkMode, bigFontSize, fontWeight = 400 ) => createTheme( {
        typography: {
            fontFamily: '"Poppins", sans-serif',
            suppressDeprecationWarnings: true,
            body1: {
                fontWeight: fontWeight,
            },
        },
        palette: {
            mode: darkMode ? 'dark' : 'light',
            salatFont: bigFontSize ? '5.7vw' : '5vw',
        },
    } );

    render () {
        const { children, darkMode, focusMode, bigFontSize, entity, forceWhite, backgroundImage, isLandscape, fontWeight } = this.props;
        const theme = this.createTheme( darkMode, bigFontSize, fontWeight );
        return (
            <ThemeProvider theme={ theme }>
                <SEO
                    title={ entity?.name || 'DinScreen' }
                    description={ entity?.description || 'Welcome to the DinScreen' }
                    logo={ entity?.logo }
                    siteName={ entity?.name }
                    address={ entity?.formatedAddress }
                    website={ entity?.website }
                />
                <Box
                    className={ darkMode ? 'drk-ffgds2' : ( forceWhite ? 'drk-ffgds2' : 'ffgds2' ) }

                    sx={ {
                        position: 'relative',
                        height: 'calc(101vh)',
                        width: 'calc(100vw)',
                        overflow: 'hidden',
                        filter: `brightness(${ focusMode ? '35%' : '100%' })`,
                        pl: '0.2vh',
                        pr: '0.2vh',
                        p: '1vh',
                        flexDirection: 'column',
                        '::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url("${ backgroundImage }")`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            filter: `brightness(${ darkMode ? '50%' : '80%' })`,
                            zIndex: -1,
                            overflow: 'hidden',
                        }
                    } }
                    onClick={ this.handleFullscreen }
                >
                    { children }
                    <Suspense fallback={ <></> }>
                        <Footer isLandscape={ isLandscape } />
                    </Suspense>
                </Box>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    entity: getEntity( state ),
    darkMode: getDarkMode( state ),
    focusMode: getFocusMode( state ),
    bigFontSize: getFontSize( state ),
    fontWeight: getFontWeigth( state ),
    tvLangsOne: getTvLangsOne( state ),
    tvLangsTwo: getTvLangsTwo( state ),
    forceWhite: getForceWhite( state ),
    backgroundImage: getBackgroundImage( state ),
    currentLanguage: getCurrentLanguage( state ),
} );

export default connect( mapStateToProps )( withTranslation()( ApplicationSettings ) );
