import React from 'react';
import GlassedBox from '../../../../layouts/glassmorphism/boxGlassed';
import SalatTitleBox from './boxes/titles';
import SalatAdhanBox from './boxes/adhan';
import SalatIqamaBox from './boxes/iqama';
import { useSelector } from 'react-redux';

import {
    getIsIqama,
    getApiPrayerTime
} from '../../../../services/selectors/prayersSelectors'

const Salat = () => {
    const isIqama = useSelector( getIsIqama );
    const prayers = useSelector( getApiPrayerTime );

    const glassedBoxStyles = {
        height: isIqama ? '37.5%' : '22%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '1.5vh',
        marginLeft: '1.5vh',
        marginBottom: isIqama ? '1vw' : '4vw',
        marginTop: isIqama ? '0.5vw' : '6.6vh'
    };

    return (
        prayers && <GlassedBox
            gmode={ 'gm-dmode' }
            styles={ glassedBoxStyles }
        >
            <SalatTitleBox />
            <SalatAdhanBox />
            { isIqama && <SalatIqamaBox /> }
        </GlassedBox>
    );
};

//export with memo
export default React.memo( Salat );
