import React from 'react';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player'

const SplashScreen = () => {
    const handleOnReady = () => {
        console.log( 'Player is ready' );
        return true

    };
    return (
        <Box
            className='gm-dmode'
            sx={ {

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#fff',
                overflow: 'hidden' // Ensure no scrolling
            }
            }
        >
            {/* Center the GIF as a small loading logo */ }
            < img
                src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_loading.gif"
                alt="Loading..."
                style={ {
                    maxWidth: '20vw', // Control the size of the GIF here
                    maxHeight: '20vw',
                } }
            />
            {/* <ReactPlayer
                url='https://general.eu-central-1.linodeobjects.com/basics/platforms/mn-intro.mov'
                width="100%"
                height="100%"
                playing
                loop
                volume={ 0 }
                muted={ true }
                onStart={ handleOnReady }
                onEnded={ handleOnReady }
                controls={ false }

                style={ {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                } }
                config={ {
                    file: {
                        attributes: {
                            preload: "auto",
                            style: { // Adding inline styles to ensure full cover
                                position: 'absolute',
                                top: '52%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                minWidth: '100%',
                                minHeight: '100%'
                            }
                        },
                    },
                } }
                onReady={ handleOnReady }
            /> */}
        </Box >
    );
};

export default SplashScreen;
