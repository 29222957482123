import React, { Fragment } from "react";

const Videos = ( { post, isVideo, video, isComment, idCss = "myVideo" } ) => {
  return (

    <Fragment>

      { isVideo ?
        <video
          allowFullScreen
          data-autoplay={ true }
          autoPlay={ true }
          controls={ false }
          muted loop id={ idCss }>
          <source src={ post.video[ 0 ] } type="video/mp4"
          />
        </video>
        :

        <iframe
          muted
          loop
          title="Moonode"
          allow="autoplay"
          id={ idCss }
          src={ post }
          allowFullScreen
          data-autoplay={ true }
          autoPlay={ true }
          controls={ false }
        />
      }
    </Fragment>

  );
};

export default Videos;
