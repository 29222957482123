import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GlassedBox = React.memo(
    ( { gmode, children, styles, extraClass = '', rerender = '' } ) => {
        const theme = useTheme();
        const computedGmode =
            gmode && ( gmode !== 'gm-dmode' && gmode !== 'gm-lmode' )
                ? gmode
                : gmode
                    ? theme.palette.mode === 'dark'
                        ? 'gm-dmode'
                        : 'gm-lmode'
                    : undefined;

        return (
            <Box
                className={ computedGmode + ' ' + extraClass }
                sx={ {
                    ...styles,
                } }
            >
                { children }
            </Box>
        );
    },
    ( prevProps, nextProps ) =>
        prevProps.styles === nextProps.styles &&
        prevProps.rerender === nextProps.rerender &&
        React.Children.toArray( prevProps.children ).toString() === React.Children.toArray( nextProps.children ).toString()
);

export default GlassedBox;