import { postPrayer } from "../../../actions/prayer";
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SplashScreen from '../../../layouts/splash';
import withParams from '../../wrappers/hoc/withParams';
import { TokenProvider } from '../../../context/tokenContext';

class PrayersGetterWithLoading extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            showSplash: true,
            token: null
        };
    }

    async fetchTvInfo () {
        const { postPrayer, params, online, code } = this.props;
        const token = await postPrayer( params.token, online, code );
        if ( token ) {
            this.setState( { token, showSplash: false } );
        }
    }

    componentDidMount () {
        this.fetchTvInfo();
        this.intervalFetch = setInterval( () => {
            this.fetchTvInfo();
        }, 1200000 );
    }

    componentWillUnmount () {
        clearInterval( this.intervalFetch );
    }

    refetchData = async () => {
        console.log( 'Refetching data...' );
        await this.fetchTvInfo();
    }

    render () {
        const { showSplash, token } = this.state;
        const { children, params } = this.props;
        if ( showSplash ) {
            return <SplashScreen />;
        }

        return (
            <TokenProvider token={ token } refetchData={ this.refetchData } dinScreenId={ params.token }>
                { children }
            </TokenProvider>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    admin: state.admin,
} );

export default connect( mapStateToProps, { postPrayer } )( withParams( PrayersGetterWithLoading ) );
