import React, { Suspense } from 'react';

import NotificationLayout from '../../views/app/features/notifications';
import ApplicationSettings from '../../context/ApplicationSettings';
import PrayerLandscape from '../../views/pages/prayersLandscape';
import SocketIoWrapperHandler from '../../views/wrappers/socket';
import PrayerPortrait from '../../views/pages/prayersPortrait';
import PrayerGetterAndLoading from '../../views/wrappers/salat';
import StateHandlerWrapper from '../../views/wrappers/state';
import Menu from '../../views/wrappers/menu';
import { useOrientation } from '../../hooks/useOrientation';


const DinScreen = () => {
    const { orientation, acceptPortrait } = useOrientation();
    const { type } = orientation;
    const isLandscape = type?.startsWith( 'landscape' );
    return (
        <Suspense fallback={ <></> } >
            <PrayerGetterAndLoading>
                <SocketIoWrapperHandler>
                    <ApplicationSettings isLandscape={ isLandscape }>
                        <NotificationLayout>
                            <StateHandlerWrapper>

                                { isLandscape ? (
                                    <>
                                        <PrayerLandscape />
                                        <Menu />
                                    </>
                                ) : acceptPortrait ? (
                                    <PrayerPortrait />
                                ) : (
                                    <>
                                        <PrayerLandscape />
                                        <Menu />
                                    </>
                                ) }
                            </StateHandlerWrapper>
                        </NotificationLayout>
                    </ApplicationSettings>
                </SocketIoWrapperHandler>
            </PrayerGetterAndLoading >
        </Suspense>
    );
};

export default React.memo( DinScreen );  
