import React, { useState } from 'react'
import './landing.min.css'
const Landing = props => {

    const [ phone, setphone ] = useState( {
        phone: ""
    } )
    const onChange = ( e ) => {
        setphone( { ...phone, [ e.target.name ]: e.target.value } );
    }
    const onClick = () => {
        window.location.replace( `https://www.moonode.tv/${ phone.phone }` )
    }
    return (
        <div className="">
            <section>
                <div className="main">
                    <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" alt="" />
                    <div className="searchBx">
                        <input type="text" id="phone" name="phone" onChange={ ( e ) => onChange( e ) } className="query" value={ phone.phone } />
                    </div>
                    <div className="button">
                        <button className="searchBtn" onClick={ ( e ) => { e.preventDefault(); onClick() } } >Access Moonode Tv</button>
                    </div>
                </div>
            </section>
        </div>

    )
}



export default Landing
