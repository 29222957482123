import React, { Fragment, useState, useEffect } from "react";

const Images = ( { post, dimensions } ) => {
  let currentTime = 20;
  const [ index, setIndex ] = useState( 0 );

  useEffect( () => {
    const intervalAds = setInterval( () => {
      if ( currentTime > 0 ) {
        currentTime--;
      } else {
        currentTime = 20;  // Reset time
        setIndex( ( prevIndex ) =>
          prevIndex + 1 >= post.image.length ? 0 : prevIndex + 1
        );
      }
    }, 1000 );
    return () => clearInterval( intervalAds );
  }, [ index, post.image.length ] );

  return (
    <Fragment>
      <img
        id="myImage_TV"
        src={ post.image[ index ] } style={ { ...dimensions, objectFit: 'fill' } } alt="displayed content" />
      { post.image.length > 1 && (
        <div style={ { position: 'absolute', display: 'flex', marginBottom: -15, right: "45%", bottom: 10 } }>
          { post.image.map( ( _, idx ) => (
            <p key={ idx } style={ { color: index === idx ? '#fcd45c' : '#404b69', fontSize: 100, margin: 10 } }>●</p>
          ) ) }
        </div>
      ) }
    </Fragment>
  );
};

export default Images;
