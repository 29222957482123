import { createSelector } from 'reselect';

// Base selector for accessing the language state slice
const getLanguage = state => state.language;

// Memoized selector for the currentLanguage property
export const getCurrentLanguage = createSelector(
    [ getLanguage ],
    language => language.currentLanguage
);
