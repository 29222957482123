import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { getApiPrayerTime, getHijriPreference } from '../../../services/selectors/prayersSelectors';

const GlassMorphismDates = () => {
    const apiPrayerTime = useSelector( getApiPrayerTime );
    const hijriPreference = useSelector( getHijriPreference );
    const [ dateFormat, setDateFormat ] = useState( 'gregorian' );

    useEffect( () => {
        const intervalId = setInterval( () => {
            setDateFormat( prevFormat => ( prevFormat === 'gregorian' ? 'hijri' : 'gregorian' ) );
        }, 50000 );
        return () => clearInterval( intervalId );
    }, [] );

    const getGregorianDate = useMemo( () => dayjs().format( 'DD/MM/YYYY' ), [] );

    const getHijriDate = useMemo( () => {
        try {
            const settings = hijriPreference === "(null)" || hijriPreference === null ? "0" : hijriPreference;
            const hijriPref = Number( settings ) + 2;
            return apiPrayerTime?.HijriDates?.hijriEn[ hijriPref ]?.date || '';
        } catch ( e ) {
            return '';
        }
    }, [ apiPrayerTime, hijriPreference ] );

    const dateContainerStyles = {
        position: 'relative',
        width: '100%',
        height: '75%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const fullDateStyles = {
        position: 'absolute',
        transition: 'opacity 0.5s ease-in-out, transform 1s ease-in-out',
        opacity: 0,
        transform: 'translate3d(0, 20px, 0)',
        fontSize: { xs: '3vw', sm: '3vw', md: '3vw', lg: '3vw', xl: '3vw' },
        '&.show': {
            opacity: 1,
            transform: 'translate3d(0, 0, 0)',
        },
        '&.slideInRight-enter': {
            transform: 'translate3d(100%, 0, 0)',
            opacity: 0,
        },
        '&.slideInRight-enter-active': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            animation: 'slideInRight 1s ease-out forwards',
        },
        '&.slideInRight-exit': {
            opacity: 1,
        },
        '&.slideInRight-exit-active': {
            opacity: 0,
            transform: 'translate3d(100%, 0, 0)',
            animation: 'slideOutRight 1s ease-out forwards',
        },
        '&.slideInRight-enter, &.slideInRight-exit': {
            willChange: 'transform, opacity',
        },
        '@keyframes slideInRight': {
            from: {
                transform: 'translate3d(100%, 0, 0)',
                opacity: 0,
            },
            to: {
                transform: 'translate3d(0, 0, 0)',
                opacity: 1,
            },
        },
        '@keyframes slideOutRight': {
            from: {
                transform: 'translate3d(0, 0, 0)',
                opacity: 1,
            },
            to: {
                transform: 'translate3d(100%, 0, 0)',
                opacity: 0,
            },
        },
    };

    return (
        <Box sx={ dateContainerStyles }>
            <Typography
                component="span"
                className={ `full-date ${ dateFormat === 'gregorian' ? 'show slideInRight-enter-active' : 'slideInRight-exit-active' }` }
                sx={ fullDateStyles }
            >
                { getGregorianDate }
            </Typography>
            <Typography
                component="span"
                className={ `full-date ${ dateFormat === 'hijri' ? 'show slideInRight-enter-active' : 'slideInRight-exit-active' }` }
                sx={ fullDateStyles }
            >
                { getHijriDate }
            </Typography>
        </Box>
    );
};

export default React.memo( GlassMorphismDates );
