import {

    GET_ADMIN_PRAYER_TV,
    SET_SOME_POSTS,
} from '../actions/types';


const initialState = {
    adminLoading: true,
    entityPrayer: {},
    entityInfo: {},
    covidUsers: {},
    tvPrayer: null,
    tvSettings: null,
    methode: null,
    school: null,
    csv: null,
    fetching: false,
    isCsv: false,
    somePosts: 0,
};

export default function ( state = initialState, action ) {
    const { type, payload } = action;
    switch ( type ) {

        case GET_ADMIN_PRAYER_TV:
            return {
                ...state,
                tvPrayer: payload,
                tvSettings: payload.tvSettings,
                somePosts: ( payload.somePosts.length ),
                adminLoading: false,
            }

        case SET_SOME_POSTS:
            return {
                ...state,
                somePosts: ( payload - 1 )
            }
        default:
            return state;
    }

}