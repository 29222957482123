import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './animation.min.css';
function Index ( { fontWeight, announcement, isArabicFont } ) {
    const arabicStyle = isArabicFont ? {
        fontFamily: 'Your Arabic Font Family Here',
    } : {};
    return (
        <>

            <Box
                id="xmoxshadow"
                sx={ {
                    left: 0,
                    right: 0,
                    bottom: '2.5vh',
                    height: '8vh',
                    width: '99%',
                    backgroundColor: '#fff',
                    p: 1,
                    boxShadow: '0 -1px 10px rgba(0, 0, 0, 0.12)',
                    borderRadius: '2vh',
                    marginLeft: '0.5vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                } }
            >
                <div
                    id={ announcement.direction === "rtl" ? "arabic" : undefined }
                    className={ ( announcement.direction === "ltr" ) ? "scroll-text-ltr" : "scroll-text-rtl" }
                    style={ {
                        direction: announcement.direction,
                        ...arabicStyle,
                        width: '100%',
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        marginTop: '2.3vh',

                    } }
                >
                    <Typography
                        component="p"
                        style={ {
                            fontSize: '8vh',
                            color: "#001",
                            fontWeight: 500,
                            lineHeight: '1.5',
                            margin: 0,
                            marginTop: '-1vh'
                        } }
                    >
                        { announcement?.message || 'بسم الله الرحمن الرحيم' }
                    </Typography>
                </div>

            </Box>
        </>
    );
}

export default Index;
