import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SEO = ( { title, description, logo, siteName, address, website } ) => (
    <HelmetProvider>
        <Helmet>
            <title>{ title }</title>
            <meta name="description" content={ description } />
            <meta property="og:title" content={ title } />
            <meta property="og:description" content={ description } />
            { logo && <meta property="og:image" content={ logo } /> }
            { siteName && <meta property="og:site_name" content={ siteName } /> }
            { address && <meta property="og:address" content={ address } /> }
            { website && <meta property="og:url" content={ website } /> }
            { logo && <link rel="icon" href={ logo } /> }
        </Helmet>
    </HelmetProvider>
);

export default SEO;
