import React from 'react';
import Tv from '../../../views/wrappers/modern';
import { useParams } from 'react-router-dom';

const View = ( { ads = true } ) => {
    const { token, time, reload } = useParams();
    return <Tv token={ token } time={ time } reload={ reload } ads={ ads } />;
};

export default View;
