import {
    handleVersionChange,
    managePhoneNumStorage,
    isLocalStorageSupported,
} from '../../../Utils/apiService';



export const setAndProcessPhone = async ( phoneNum, data ) => {
    try {
        //add phone to list of caching phones
        managePhoneNumStorage( phoneNum );

        // check if the version has changed 
        if ( isLocalStorageSupported() ) {
            const currentVersion = localStorage.getItem( 'version' );
            handleVersionChange( currentVersion, data.data.version, phoneNum, data );
        }

    } catch ( error ) {
        return null; // fake data;
    }
};

export const getPayloadFromLocalStorage = async ( phoneNum ) => {
    let payload;
    try {
        if ( await isLocalStorageSupported() ) {
            payload = localStorage.getItem( phoneNum );
        } else {
            payload = null;//fake data
        }
        return payload;
    } catch ( error ) {
        return null; // fake data;
    }
};

export const getLatestLocalStorageUpdate = ( phoneNum ) => {
    let payload;
    try {
        if ( isLocalStorageSupported() ) {
            payload = localStorage.getItem( phoneNum + 'date' );
        } else {
            payload = null;//fake data
        }
        return payload;
    } catch ( error ) {
        return null; // fake data;
    }
};


export const setLatestLocalStorageUpdate = ( phoneNum, date ) => {
    try {
        if ( isLocalStorageSupported() ) {
            localStorage.setItem( phoneNum + 'date', phoneNum + date );
        }
    } catch ( error ) {
        return null; // fake data;
    }
}