// utils.js

import axios from 'axios';


export const isOverseasCountry = ( country ) => {
    const northAmericanCountries = [ "Mauritius" ];
    return northAmericanCountries.includes( country );
};

export const isLocalStorageSupported = async () => {
    let test = 'test';
    try {
        localStorage.setItem( test, test );
        localStorage.removeItem( test );
        return true;
    } catch ( e ) {
        console.log( e );
        return false;
    }
};

export const fetchDataFromServer = async ( phoneNum ) => {
    try {
        const res = await axios.get( `/tapy/masjid/tv/${ phoneNum }` );
        if ( !res.data.data ) {
            throw new Error( 'No data received from server' );
        }
        return res.data;
    } catch ( error ) {
        return false;
    }

};

export const fetchDataFromLocalStorage = async ( phoneNum, localPrayerTime ) => {
    let res = await localPrayerTime( phoneNum );
    if ( !res ) {
        throw new Error( 'No data in local storage' );
    }
    return res;
};


export const managePhoneNumStorage = ( newPhoneNum ) => {
    try {
        if ( isLocalStorageSupported() ) {
            const MAX_PHONE_NUMS = 3; // Set your maximum limit

            // Check if localStorage has already been cleared for this update, but preserve "code"
            const isCleared = localStorage.getItem( 'isCleared' );
            const preservedCode = localStorage.getItem( 'code' ); // Preserve the code before clearing

            if ( !isCleared ) {
                // Iterate over localStorage and remove everything except "code"
                for ( let i = 0; i < localStorage.length; i++ ) {
                    const key = localStorage.key( i );
                    if ( key !== 'code' ) {
                        localStorage.removeItem( key );
                        i--; // Decrement the counter as the list length decreases
                    }
                }

                // After clearing, reset other items as needed
                localStorage.setItem( 'isCleared', 'true' );
                if ( preservedCode ) {
                    localStorage.setItem( 'code', preservedCode ); // Restore the preserved code
                }
            }

            // Get the list of stored phone numbers (which may be empty after clearing)
            const phoneNumList = JSON.parse( localStorage.getItem( 'phoneNumList' ) ) || [];

            // Check if new phone number already exists in the list
            if ( !phoneNumList.includes( newPhoneNum ) ) {
                // If new phone number is not in the list and we're at the maximum number of phone numbers
                if ( phoneNumList.length === MAX_PHONE_NUMS ) {
                    // Remove the oldest phone number from the list
                    phoneNumList.shift();
                }

                // Add the new phone number to the list and store it back in localStorage
                phoneNumList.push( newPhoneNum );
                localStorage.setItem( 'phoneNumList', JSON.stringify( phoneNumList ) );
            }
        }
    } catch ( error ) {
        console.error( 'Error managing phone number storage:', error );
    }
};

export const handleVersionChange = ( currentVersion, newVersion, phoneNum, data ) => {
    try {
        localStorage.setItem( phoneNum, JSON.stringify( data ) );
        if ( currentVersion !== newVersion ) {
            localStorage.setItem( 'version', newVersion );
            const updatedVersion = localStorage.getItem( 'version' );

            if ( updatedVersion !== newVersion ) {
            } else {
                if ( 'serviceWorker' in navigator && navigator.serviceWorker.controller ) {
                    navigator.serviceWorker.controller.postMessage( { action: 'clearCaches' } );
                } else {
                }
            }
        }
    } catch ( e ) {
        console.log( "steivel. " )
    }
};



export const isOnline = async () => {
    try {
        if ( navigator.onLine ) {
            return true
        } else {
            return false
        }
    } catch ( e ) {
        console.log( e );
        return false;
    }
};


export const getCodeFromStorage = () => {
    let code = 'NONONO';
    const codeInStorage = localStorage.getItem( "code" );
    if ( codeInStorage ) {
        code = codeInStorage
    }
    return code;
};

