import dayjs from 'dayjs';
import { format, differenceInSeconds, isAfter, isBefore, parse } from 'date-fns'

// export const adhanTime = function ( adhan ) {
//     try {
//         console.log( adhan );
//         let now = format( new Date(), "HH:mm" )
//         let valid = false;
//         if ( adhan.Fajr === now ) {
//             valid = true
//         } else if ( adhan.Dhuhr === now ) {
//             valid = true
//         } else if ( adhan.Asr === now ) {
//             valid = true
//         } else if ( adhan.Maghrib === now ) {
//             valid = true
//         } else if ( adhan.Isha === now ) {
//             valid = true
//         }
//         return valid;
//     } catch ( error ) {
//         return false;
//     }
// };


export const adhanTime = function ( adhan ) {
    try {
        const now = format( new Date(), "HH:mm" );
        const today = new Date().getDay(); // 0 (Sunday) - 6 (Saturday)
        const validTimes = [ "Fajr", "Dhuhr", "Asr", "Maghrib", "Isha" ];
        // Check if today is Friday (5) and the adhan time is Dhuhr.
        // If so, return false.
        if ( today === 5 && adhan.Dhuhr === now ) {
            return false;
        }
        // Otherwise, check if the current time matches any valid adhan time.
        return validTimes.some( time => adhan[ time ] === now );

    } catch ( error ) {
        return false;
    }
};

export const iqamaTime = function ( iqama ) {
    try {
        const now = format( new Date(), "HH:mm" );
        const today = new Date().getDay(); // 0 (Sunday) - 6 (Saturday)
        const validTimes = [ "Fajr", "Dhuhr", "Asr", "Maghrib", "Isha" ];

        // Check if today is Friday (5) and the iqama time is Dhuhr.
        // If so, return false.
        if ( today === 5 && iqama.Dhuhr === now ) {
            return false;
        }

        // Otherwise, check if the current time matches any valid iqama time.
        return validTimes.some( time => iqama[ time ] === now );

    } catch ( error ) {
        return false;
    }
};
// export const iqamaTime = function ( iqamaTime ) {
//     console.log( iqamaTime );
//     try {
//         let now = format( new Date(), "HH:mm" )
//         let valid = false;
//         if ( iqamaTime.Fajr === now ) {
//             valid = true
//         } else if ( iqamaTime.Dhuhr === now ) {
//             valid = true
//         } else if ( iqamaTime.Asr === now ) {
//             valid = true
//         } else if ( iqamaTime.Maghrib === now ) {
//             valid = true
//         } else if ( iqamaTime.Isha === now ) {
//             valid = true
//         }
//         return valid;
//     } catch ( error ) {
//         return false
//     }
// };

export const calculeNextIqama = function ( iqamaTime, type ) {
    try {
        let now = format( new Date(), "HH:mm" )
        let time, befor;
        switch ( type ) {
            case "Fajr":
                time = now < iqamaTime.Fajr
                befor = now > iqamaTime.Isha;
                return time || befor;
            case "Dhuhr":
                time = now < iqamaTime.Dhuhr;
                befor = now > iqamaTime.Fajr;
                return time && befor;
            case "Asr":
                time = now < iqamaTime.Asr;
                befor = now > iqamaTime.Dhuhr;
                return time && befor;
            case "Maghrib":
                time = now < iqamaTime.Maghrib;
                befor = now > iqamaTime.Asr;
                return time && befor;
            case "Isha":
                time = now < iqamaTime.Isha;
                befor = now > iqamaTime.Maghrib;
                return time && befor;
        }
    } catch ( error ) {
        return false;
    }
};

export const reloadMidnight = function () {
    try {
        let now = format( new Date(), "HH:mm:ss" )
        if ( now === "00:00:10" ) {
            window.location.reload();
        }
    } catch ( error ) {
        return false;
    }

};

// const nextPrayerTimer = function ( now, iqamaTime, adhanTime, salat ) {
//     let _now = new Date( "1988-08-22" + "T" + now );
//     let _iqamaTime = new Date( "1988-08-22" + "T" + iqamaTime )
//     let _adhanTime = new Date( "1988-08-22" + "T" + adhanTime.replace( /\s+/g, '' ) )
//     return { salat: salat, timeToIqama: differenceInSeconds( _iqamaTime, _now ), timeToAdhan: differenceInSeconds( _adhanTime, _now ), iqamaTimer: ( isAfter( _now, _adhanTime ) && isBefore( _now, _iqamaTime ) ) }
// }
const nextPrayerTimer = function ( now, iqamaTime, adhanTime, salat ) {

    let _now = parse( now, 'HH:mm:ss', new Date() );
    let _iqamaTime = parse( iqamaTime, 'HH:mm', new Date() );
    let _adhanTime = parse( adhanTime, 'HH:mm', new Date() );
    let iqamaTimer = isAfter( _now, _adhanTime ) && isBefore( _now, _iqamaTime );

    return {
        salat: salat,
        timeToIqama: differenceInSeconds( _iqamaTime, _now ),
        timeToAdhan: differenceInSeconds( _adhanTime, _now ),
        iqamaTimer: iqamaTimer,
    };
};
export const whatIsNext = function ( iqamaTime, apiPrayerTime ) {
    try {
        let now = format( new Date(), "HH:mm:ss" )
        let time, befor;
        let valid = true;
        time = now < iqamaTime.Fajr
        befor = now > iqamaTime.Isha;
        if ( time || befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, iqamaTime.Fajr, apiPrayerTime.Fajr, "Fajr" )
        }
        time = now < iqamaTime.Dhuhr;
        befor = now > iqamaTime.Fajr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, iqamaTime.Dhuhr, apiPrayerTime.Dhuhr, "Dhuhr" );
        }
        time = now < iqamaTime.Asr;
        befor = now > iqamaTime.Dhuhr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, iqamaTime.Asr, apiPrayerTime.Asr, "Asr" );
        }
        time = now < iqamaTime.Maghrib;
        befor = now > iqamaTime.Asr;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, iqamaTime.Maghrib, apiPrayerTime.Maghrib, "Maghrib" );
        }
        time = now < iqamaTime.Isha;
        befor = now > iqamaTime.Maghrib;
        if ( time && befor && valid ) {
            valid = false;
            return nextPrayerTimer( now, iqamaTime.Isha, apiPrayerTime.Isha, "Isha" );
        }

    } catch ( error ) {
        return { salat: "Salat", time: 0 }
    }
};

export const tvNotificationTime = function ( tvPrayer ) {
    try {
        let now = format( new Date(), "HH:mm" )
        const { Sunrise, Midnight, Imsak } = tvPrayer;
        switch ( now ) {
            case Sunrise:
                return 1;
            case Midnight:
                return 2;
            case Imsak:
                return 3;
            default:
                return 0;
        }
    } catch ( error ) {
        return { salat: "Salat", time: 0 }
    }
};

export const convertTimeWorld = function ( isChange, time ) {
    try {

        if ( isChange ) {
            // Check correct time format and split into components
            time = time.trim().toString().match( /^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/ ) || [ time ];
            if ( time.length > 1 ) { // If time format correct
                time = time.slice( 1 );  // Remove full string match value
                // time[ 5 ] = +time[ 0 ] < 12 ? 'AM' : 'PM'; // Set AM/PM
                time[ 0 ] = +time[ 0 ] % 12 || 12; // Adjust hours
            }
        }
        return time; // return adjusted time or original string

    } catch ( error ) {
        console.log( "Error in Convert Time World " );
        return time; // return adjusted time or original string

    }
};


const nextPrayerTimerDayjs = ( now, iqamaTime, adhanTime, salat ) => {
    const _now = dayjs( now, 'HH:mm:ss' );
    let _iqamaTime = dayjs( iqamaTime, 'HH:mm' );
    let _adhanTime = dayjs( adhanTime, 'HH:mm' );

    // Adjust times to next day if necessary
    if ( salat === 'Fajr' && _now.isAfter( _adhanTime ) && _now.isBefore( _iqamaTime ) ) {
        _adhanTime = _adhanTime.add( 1, 'day' );
    } else if ( _now.isAfter( _iqamaTime ) ) {
        _iqamaTime = _iqamaTime.add( 1, 'day' );
        _adhanTime = _adhanTime.add( 1, 'day' );
    }

    const iqamaTimer = _now.isAfter( _adhanTime ) && _now.isBefore( _iqamaTime );

    return {
        salat: salat,
        timeToIqama: _iqamaTime.diff( _now, 'second' ),
        timeToAdhan: _adhanTime.diff( _now, 'second' ),
        iqamaTimer: iqamaTimer,
    };
};


export const whatIsNextDayjs = ( iqamaTime, apiPrayerTime ) => {
    try {
        const now = dayjs().format( "HH:mm:ss" );
        const prayerTimes = [
            { name: "Fajr", iqama: iqamaTime.Fajr, adhan: apiPrayerTime.Fajr },
            { name: "Dhuhr", iqama: iqamaTime.Dhuhr, adhan: apiPrayerTime.Dhuhr },
            { name: "Asr", iqama: iqamaTime.Asr, adhan: apiPrayerTime.Asr },
            { name: "Maghrib", iqama: iqamaTime.Maghrib, adhan: apiPrayerTime.Maghrib },
            { name: "Isha", iqama: iqamaTime.Isha, adhan: apiPrayerTime.Isha }
        ];

        for ( let i = 0; i < prayerTimes.length; i++ ) {
            const { name, iqama, adhan } = prayerTimes[ i ];
            if ( now < iqama || ( name === "Fajr" && now > "00:00:00" && now < iqama ) ) {
                return nextPrayerTimerDayjs( now, iqama, adhan, name );
            }
        }

        // If we are past Isha and none of the conditions were met, return Fajr as the next prayer
        return nextPrayerTimerDayjs( now, iqamaTime.Fajr, apiPrayerTime.Fajr, 'Fajr' );
    } catch ( error ) {
        return { salat: "Salat", time: 0 };
    }
};
