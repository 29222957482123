import React, { Fragment } from "react";

const AdhanText = ( { isAdhan } ) => {
    function formatText ( text, charsPerLine ) {
        text = text.replace( /-/g, '&#8209;' );
        let formattedText = '';
        let currentLineCharCount = 0;
        let i = 0;

        // Splitting the string into an array of characters, with support for multibyte characters
        let characters = Array.from( text );

        for ( let char of characters ) {
            // Break the loop if i is greater than or equal to 8

            if ( currentLineCharCount >= charsPerLine || ( i > 4 && currentLineCharCount === 4 ) ) {
                formattedText += '\n';
                currentLineCharCount = 0;
            }
            formattedText += char;
            currentLineCharCount++;
            i++;
        }
        return formattedText;
    }


    return (
        <Fragment>
            <div className="effects">
                <div className="effect-holder">
                    <div className="dropdown-style">
                        <small>{ formatText( isAdhan ? "ADHAN" : "IQAMA", 7 ) }</small>
                    </div>
                </div>
                <div className="effect-holder">
                    <div className="dropdown-style logo-holder">
                        <img src="https://general.eu-central-1.linodeobjects.com/basics/platforms/moonode_logo.png" style={ { height: "60%", width: "60%" } } alt="Logo" />
                    </div>
                </div>
                <div className="effect-holder">
                    <div className="dropdown-style">
                        <small> { formatText( isAdhan ? "الأذان" : "الإقامة", 7 ) } </small>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdhanText;
